import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '../utils/apollo-client/apollo-react-hooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {"context":{"clientName":"community"}} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: number;
};

export type Query = {
  __typename?: 'Query';
  /**
   * 관리자 전용
   *
   * 메뉴 id로 메뉴 1개를 가져온다.
   *
   * 성공 시 해당 메뉴를 반환한다.
   *
   * 해당 id를 가진 메뉴가 없다면 null을 반환한다.
   */
  admin_menu?: Maybe<IMenu>;
  banLogs?: Maybe<BanLogConnection>;
  bestOfBestPostPreviews?: Maybe<PostConnection>;
  bestOfBestRequirement?: Maybe<CollectionRequirement>;
  bestPostList?: Maybe<BestPostListPayload>;
  bestPostRequirement?: Maybe<CollectionRequirement>;
  board?: Maybe<Board>;
  boardBySlug?: Maybe<Board>;
  boardNavigation?: Maybe<BoardNavigation>;
  boardNavigations?: Maybe<BoardNavigationConnection>;
  boards?: Maybe<BoardConnection>;
  boardsByID?: Maybe<Array<Maybe<Board>>>;
  bookmarks: BookmarkableConnection;
  comment?: Maybe<Comment>;
  /** 댓글 신고 내역 조회 */
  commentAbuseReportLogs: CommentAbuseReportLogConnection;
  comments?: Maybe<CommentConnection>;
  config?: Maybe<Config>;
  configsAll: Array<Config>;
  currentAdByPlacementCode?: Maybe<Ad>;
  currentUser?: Maybe<User>;
  currentUserScrap?: Maybe<UserScrap>;
  currentUserScraps: CurrentUserScrapConnection;
  currentUserScrapsCount: Scalars['Int'];
  dailyCommentByUsers?: Maybe<DailyCommentByUserConnection>;
  dailyPostByUsers?: Maybe<DailyPostByUserConnection>;
  embeddable?: Maybe<Embeddable>;
  embeddableByUri?: Maybe<Embeddable>;
  embeddableJsonLdByUri?: Maybe<EmbeddableJsonLd>;
  /** embeddable을 삽입한 게시물 조회. */
  embeddablePosts: PostConnection;
  externalNotificationsUnreadCount: Scalars['Int'];
  /**
   * 그룹 id로 그룹 1개를 가져온다.
   *
   * 성공 시 해당 그룹을 반환한다.
   *
   * 해당 id를 가진 그룹이 없다면 null을 반환한다.
   */
  group?: Maybe<Group>;
  /**
   * 조건에 해당하는 그룹들을 가져온다.
   *
   * 성공 시 그룹들과 전체 숫자를 반환한다.
   */
  groups: GroupConnection;
  mentorBoards: BoardConnection;
  mentorDutiesByName: Array<MentorDuty>;
  mentorDutyByName?: Maybe<MentorDuty>;
  mentorRewardAmountSettings: RewardAmountSettings;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorRewardAmountSettingsChangeLogs: RewardAmountSettingsChangeLogConnection;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorRewardLogs: MentorRewardLogConnection;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentors: UserConnection;
  mentorSchoolByName: Array<MentorSchool>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorUserKpi: MentorUserKpiConnection;
  menu?: Maybe<Menu>;
  /**
   * 조건에 해당하는 메뉴들을 가져온다.
   *
   * 성공 시 메뉴들과 전체 숫자를 반환한다.
   */
  menus: MenuConnection;
  message?: Maybe<Message>;
  messages?: Maybe<MessageConnection>;
  messagesByUserId?: Maybe<MessageConnection>;
  messageUnreadCount: Scalars['Int'];
  notifications?: Maybe<NotificationConnection>;
  notificationsUnreadCount: Scalars['Int'];
  oidcClientID: Scalars['String'];
  /** 관리자만 이용 가능한 서비스 입니다. */
  organizationGroup?: Maybe<OrganizationGroup>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  organizationGroups: OrganizationGroupConnection;
  /** 관리자만 이용 가능한 서비스 입니다. */
  organizationsAddedByAdmin: OrganizationConnection;
  post?: Maybe<Post>;
  /** 게시글 신고 내역 조회 */
  postAbuseReportLogs: PostAbuseReportLogConnection;
  postCollection?: Maybe<PostCollection>;
  postCollections?: Maybe<PostCollectionConnection>;
  postCollectionsByID?: Maybe<Array<Maybe<PostCollection>>>;
  posts?: Maybe<PostConnection>;
  postsByID?: Maybe<Array<Maybe<Post>>>;
  postSearch?: Maybe<PostSearchResultConnection>;
  /** 신고된 댓글 조회 */
  reportedComments?: Maybe<CommentConnection>;
  /** 신고된 게시물 조회 */
  reportedPosts?: Maybe<PostConnection>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  requestCashoutLogs: RequestCashoutLogConnection;
  /** 관리자만 이용 가능한 서비스 입니다. */
  rewardKpi: RewardKpiConnection;
  searchMentorDuties: Array<MentorDuty>;
  /**
   * 사이트맵 id로 사이트맵 1개를 가져온다.
   *
   * 성공 시 해당 사이트맵을 반환한다.
   *
   * 해당 id를 가진 사이트맵이 없다면 null을 반환한다.
   */
  sitemap?: Maybe<Sitemap>;
  /**
   * 조건에 해당하는 사이트맵들을 가져온다.
   *
   * 성공 시 사이트맵들과 전체 숫자를 반환한다.
   */
  sitemaps: SitemapConnection;
  tag?: Maybe<Tag>;
  tags: TagConnection;
  tagsByID: Array<Tag>;
  trash: Trash;
  /** 삭제된 댓글 조회 */
  trashedComments?: Maybe<CommentConnection>;
  /** 삭제된 게시물 조회 */
  trashedPosts?: Maybe<PostConnection>;
  trashes: TrashConnection;
  user?: Maybe<User>;
  userByExternalId?: Maybe<User>;
  users: UserConnection;
  usersByExternalId: Array<Maybe<User>>;
  usersByID: Array<User>;
  viewerGroups?: Maybe<Array<Maybe<Group>>>;
  viewerMentorComments: CommentConnection;
  viewerMentorRewardLogs: MentorRewardLogConnection;
  viewerRecommendedMentorPosts: PostConnection;
};


export type QueryAdmin_MenuArgs = {
  id: Scalars['ID'];
};


export type QueryBanLogsArgs = {
  filterBy?: InputMaybe<BanLogsFilter>;
  orderBy?: InputMaybe<BanLogsOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryBestOfBestPostPreviewsArgs = {
  filterBy?: InputMaybe<PostFiltersInput>;
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryBestPostListArgs = {
  input: BestPostListInput;
};


export type QueryBoardArgs = {
  id: Scalars['ID'];
};


export type QueryBoardBySlugArgs = {
  boardSlug: Scalars['String'];
  boardTab?: InputMaybe<BoardTab>;
  isBest?: InputMaybe<Scalars['Boolean']>;
  isNotice?: InputMaybe<Scalars['Boolean']>;
};


export type QueryBoardNavigationArgs = {
  id: Scalars['ID'];
};


export type QueryBoardNavigationsArgs = {
  filterBy?: InputMaybe<BoardNavigationFiltersInput>;
};


export type QueryBoardsArgs = {
  filterBy?: InputMaybe<BoardFiltersInput>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryBoardsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryBookmarksArgs = {
  filterBy?: InputMaybe<BookmarkFilter>;
  orderBy?: InputMaybe<BookmarkOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommentAbuseReportLogsArgs = {
  filterBy?: InputMaybe<CommentAbuseReportLogFilters>;
  orderBy?: InputMaybe<CommentAbuseReportLogOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCommentsArgs = {
  filterBy?: InputMaybe<CommentFiltersInput>;
  orderBy?: InputMaybe<CommentOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryConfigArgs = {
  id: Scalars['ID'];
};


export type QueryCurrentAdByPlacementCodeArgs = {
  code: Scalars['String'];
  currentTime: Scalars['String'];
};


export type QueryCurrentUserScrapArgs = {
  postID: Scalars['ID'];
};


export type QueryCurrentUserScrapsArgs = {
  filterBy?: InputMaybe<CurrentUserScrapsFilter>;
  orderBy?: InputMaybe<UserScrapOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryCurrentUserScrapsCountArgs = {
  filterBy: CurrentUserScrapsFilter;
};


export type QueryDailyCommentByUsersArgs = {
  filterBy?: InputMaybe<DailyCommentByUserFilters>;
  orderBy?: InputMaybe<DailyCommentByUserOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryDailyPostByUsersArgs = {
  filterBy?: InputMaybe<DailyPostByUserFilters>;
  orderBy?: InputMaybe<DailyPostByUserOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryEmbeddableArgs = {
  id: Scalars['ID'];
};


export type QueryEmbeddableByUriArgs = {
  uri: Scalars['String'];
};


export type QueryEmbeddableJsonLdByUriArgs = {
  uri: Scalars['String'];
};


export type QueryEmbeddablePostsArgs = {
  filterBy: EmbeddablePostFilter;
  orderBy?: InputMaybe<EmbeddablePostOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGroupsArgs = {
  filterBy?: InputMaybe<GroupFilters>;
};


export type QueryMentorBoardsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryMentorDutiesByNameArgs = {
  name: Scalars['String'];
};


export type QueryMentorDutyByNameArgs = {
  name: Scalars['String'];
};


export type QueryMentorRewardAmountSettingsChangeLogsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryMentorRewardLogsArgs = {
  filterBy?: InputMaybe<MentorRewardLogFilters>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryMentorsArgs = {
  filterBy?: InputMaybe<MentorUserFilters>;
  orderBy?: InputMaybe<MentorUserOrders>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryMentorSchoolByNameArgs = {
  name: Scalars['String'];
};


export type QueryMentorUserKpiArgs = {
  filterBy?: InputMaybe<MentorUserKpiFilters>;
  groupBy?: InputMaybe<MentorUserKpiGroups>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryMenusArgs = {
  filterBy?: InputMaybe<MenuFilters>;
};


export type QueryMessageArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  orderBy?: InputMaybe<MessageOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryMessagesByUserIdArgs = {
  filterBy?: InputMaybe<MessageFiltersInput>;
  orderBy?: InputMaybe<MessageOrder>;
  pagination?: InputMaybe<Pagination>;
  userID: Scalars['ID'];
};


export type QueryNotificationsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryOrganizationGroupArgs = {
  id: Scalars['ID'];
};


export type QueryOrganizationGroupsArgs = {
  filterBy?: InputMaybe<OrganizationGroupFilters>;
};


export type QueryOrganizationsAddedByAdminArgs = {
  filterBy?: InputMaybe<OrganizationsAddedByAdminFiltersInput>;
  pagination?: Pagination;
};


export type QueryPostArgs = {
  fromBestList?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  permissionType?: InputMaybe<PermissionType>;
};


export type QueryPostAbuseReportLogsArgs = {
  filterBy?: InputMaybe<PostAbuseReportLogFilters>;
  orderBy?: InputMaybe<PostAbuseReportLogOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryPostCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryPostCollectionsArgs = {
  filterBy?: InputMaybe<PostCollectionFiltersInput>;
  orderBy?: InputMaybe<PostCollectionOrder>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryPostCollectionsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPostsArgs = {
  filterBy?: InputMaybe<PostFiltersInput>;
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryPostsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPostSearchArgs = {
  filterBy: PostSearchFiltersInput;
  orderBy?: InputMaybe<PostSearchOrderInput>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryReportedCommentsArgs = {
  filterBy?: InputMaybe<CommentReportFilters>;
  orderBy?: InputMaybe<CommentReportOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryReportedPostsArgs = {
  filterBy?: InputMaybe<PostReportFilters>;
  orderBy?: InputMaybe<PostReportOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryRequestCashoutLogsArgs = {
  filterBy?: InputMaybe<RequestCashoutLogFilters>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryRewardKpiArgs = {
  filterBy?: InputMaybe<RewardKpiFilters>;
  groupBy?: InputMaybe<RewardKpiGroups>;
  pagination?: InputMaybe<Pagination>;
};


export type QuerySearchMentorDutiesArgs = {
  filterBy: SearchMentorDutiesFilters;
};


export type QuerySitemapArgs = {
  id: Scalars['ID'];
};


export type QuerySitemapsArgs = {
  filterBy?: InputMaybe<SitemapFiltersInput>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  filterBy?: InputMaybe<TagFilters>;
  orderBy?: InputMaybe<TagOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryTagsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTrashArgs = {
  id: Scalars['ID'];
};


export type QueryTrashedCommentsArgs = {
  filterBy?: InputMaybe<CommentTrashFilters>;
  orderBy?: InputMaybe<CommentTrashOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryTrashedPostsArgs = {
  filterBy?: InputMaybe<PostTrashFilters>;
  orderBy?: InputMaybe<PostTrashOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryTrashesArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByExternalIdArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filterBy?: InputMaybe<UserFilters>;
  orderBy?: InputMaybe<UserOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type QueryUsersByExternalIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryViewerMentorCommentsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryViewerMentorRewardLogsArgs = {
  pagination?: InputMaybe<Pagination>;
};


export type QueryViewerRecommendedMentorPostsArgs = {
  filterBy?: InputMaybe<ViewerRecommendedMentorPostFilters>;
  pagination?: InputMaybe<Pagination>;
};

/**
 * 관리자 전용
 *
 * 메뉴 타입을 구현하기 위한 인터페이스
 */
export type IMenu = {
  /** 현재 매뉴의 자식 메뉴 리스트 */
  children: Array<IMenu>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOrder: Scalars['String'];
  /**
   * 현재 메뉴의 부모 메뉴
   *
   * 사이트맵의 자식인 경우 null
   */
  parent?: Maybe<IMenu>;
  /**
   * 모든 사용자: 비어있는 리스트
   *
   * 관리자는 단독으로만 존재 가능
   *
   * 선택 그룹 사용자: [그룹1, 그룹2, ...]
   */
  permissionViewMenuGroups: Array<Group>;
  /** 현재 메뉴가 속한 사이트맵 */
  sitemap?: Maybe<Sitemap>;
  title?: Maybe<Scalars['String']>;
};

/** 그룹 */
export type Group = {
  __typename?: 'Group';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** 관리자 그룹인지 여부를 반환합니다. (현재 제대로 작동하지 않습니다. 관리자 그룹에 넣는다고 관리자가 되지 않습니다.) */
  isAdmin: Scalars['Boolean'];
  /** 유저가 처음 커뮤니티에 들어올 때 기본으로 배정되는 그룹인지 여부를 반환합니다. (현재 제대로 작동하지 않습니다. 기본 그룹에 배정되지 않습니다.) */
  isDefault: Scalars['Boolean'];
  title: Scalars['String'];
  /**
   * 해당 그룹에 존재하는 유저들을 반환합니다.
   *
   * 관리자 전용
   */
  users: UserConnection;
};


/** 그룹 */
export type GroupUsersArgs = {
  filterBy?: InputMaybe<UserFilters>;
  orderBy?: InputMaybe<UserOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type UserFilters = {
  q?: InputMaybe<Scalars['String']>;
};

export type UserOrder = {
  direction: OrderDirection;
  field: UserOrderField;
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum UserOrderField {
  ID = 'ID'
}

export type Pagination = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  nodes: Array<User>;
  totalCount: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  acceptedMentorCommentCount: Scalars['Int'];
  allowMessage?: Maybe<Scalars['Boolean']>;
  bankInformation?: Maybe<BankInformation>;
  banLog?: Maybe<BanLogConnection>;
  duty?: Maybe<MentorDuty>;
  dutyInReview?: Maybe<MentorDuty>;
  dutyName?: Maybe<Scalars['String']>;
  dutyNameInReview?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  externalID?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  isMentorPending: Scalars['Boolean'];
  isMentorUpdateInReview: Scalars['Boolean'];
  lastBanLog?: Maybe<BanLog>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorApprovedAt?: Maybe<Scalars['Date']>;
  mentorAuthenticationDocuments: MentorDocumentConnection;
  mentorAuthenticationEmail?: Maybe<Scalars['String']>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorAuthenticationMethod?: Maybe<MentorAuthenticationMethod>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorAuthenticationRequestedAt?: Maybe<Scalars['Date']>;
  mentorAuthenticationStatus?: Maybe<MentorAuthenticationStatus>;
  mentorCommentAcceptedRate: Scalars['Int'];
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorComments: CommentConnection;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorRejectedAt?: Maybe<Scalars['Date']>;
  mentorRejectionReason?: Maybe<Scalars['String']>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorRevokedAt?: Maybe<Scalars['Date']>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  mentorRevokeReason?: Maybe<Scalars['String']>;
  monthlyExpiringRewardAmount: Scalars['Int'];
  monthlyRequestCashoutLimitAmount: Scalars['Int'];
  monthlyRequestedCashoutAmount: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
  organizationName?: Maybe<Scalars['String']>;
  organizationNameInReview?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  phoneNumberInReview?: Maybe<Scalars['String']>;
  posts?: Maybe<PostConnection>;
  profileImageURL?: Maybe<Scalars['String']>;
  rewardAmount: Scalars['Int'];
  /** 관리자만 이용 가능한 서비스 입니다. */
  rewardLogs: MentorRewardLogConnection;
  schoolName?: Maybe<Scalars['String']>;
  schoolNameInReview?: Maybe<Scalars['String']>;
  scrapCount?: Maybe<Scalars['Int']>;
  totalCommentCount: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
  weeklyMentorCommentAcceptedRate: Scalars['Int'];
};


export type UserMentorCommentsArgs = {
  filterBy?: InputMaybe<MentorCommentsFilters>;
  pagination?: InputMaybe<Pagination>;
};


export type UserPostsArgs = {
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type UserRewardLogsArgs = {
  filterBy?: InputMaybe<MentorRewardLogFilters>;
  pagination?: InputMaybe<Pagination>;
};

export type BankInformation = {
  __typename?: 'BankInformation';
  bank: Bank;
  bankAccountNumber: Scalars['String'];
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  realName: Scalars['String'];
  user?: Maybe<User>;
};

export enum Bank {
  BUSAN_BANK = 'BUSAN_BANK',
  CITI_BANK = 'CITI_BANK',
  DAEGU_BANK = 'DAEGU_BANK',
  GWANGJU_BANK = 'GWANGJU_BANK',
  GYEONGNAM_BANK = 'GYEONGNAM_BANK',
  HANA_BANK = 'HANA_BANK',
  HMC = 'HMC',
  IBK_BANK = 'IBK_BANK',
  JEJU_BANK = 'JEJU_BANK',
  JEONBUK_BANK = 'JEONBUK_BANK',
  K_BANK = 'K_BANK',
  KAKAO_BANK = 'KAKAO_BANK',
  KB_BANK = 'KB_BANK',
  KDB_BANK = 'KDB_BANK',
  NH_BANK = 'NH_BANK',
  POST_OFFICE_BANK = 'POST_OFFICE_BANK',
  SAEMAEUL_GUMGO = 'SAEMAEUL_GUMGO',
  SC_BANK = 'SC_BANK',
  SH_BANK = 'SH_BANK',
  SHINHAN_BANK = 'SHINHAN_BANK',
  SHINHYUP = 'SHINHYUP',
  SJ = 'SJ',
  TOSS_BANK = 'TOSS_BANK',
  WOORI_BANK = 'WOORI_BANK'
}

export type BanLogConnection = {
  __typename?: 'BanLogConnection';
  nodes?: Maybe<Array<BanLog>>;
  totalCount: Scalars['Int'];
};

export type BanLog = {
  __typename?: 'BanLog';
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['Date']>;
  user?: Maybe<User>;
};

export type MentorDuty = {
  __typename?: 'MentorDuty';
  children: Array<MentorDuty>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent?: Maybe<MentorDuty>;
};

export type MentorDocumentConnection = {
  __typename?: 'MentorDocumentConnection';
  nodes: Array<MentorDocument>;
  totalCount: Scalars['Int'];
};

export type MentorDocument = {
  __typename?: 'MentorDocument';
  id: Scalars['ID'];
  name: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export enum MentorAuthenticationMethod {
  DOCUMENT = 'DOCUMENT',
  EMAIL = 'EMAIL'
}

export enum MentorAuthenticationStatus {
  APPROVED = 'APPROVED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED',
  REJECTED = 'REJECTED'
}

export type MentorCommentsFilters = {
  /** 댓글 생성 날짜 범위 */
  createdAtRange?: InputMaybe<DateRange>;
  /** 댓글 내용 검색 쿼리 */
  query?: InputMaybe<Scalars['String']>;
};

export type DateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  nodes: Array<Comment>;
  totalCount: Scalars['Int'];
};

export type Comment = {
  __typename?: 'Comment';
  /** 운영자가 신고된 댓글을 가장 최근에 확인한 날짜 */
  adminReportReadAt?: Maybe<Scalars['Date']>;
  /** 익명 닉네임 번호 */
  anonymousIndex?: Maybe<Scalars['Int']>;
  attachments: Array<Attachment>;
  author?: Maybe<User>;
  board?: Maybe<Board>;
  boardID?: Maybe<Scalars['Int']>;
  /** @deprecated Use `childComments`. */
  childComment: Array<Comment>;
  /** @deprecated Use `childComments.totalCount`. */
  childCommentCount: Scalars['Int'];
  childComments?: Maybe<CommentConnection>;
  content?: Maybe<Scalars['String']>;
  contentText?: Maybe<Scalars['String']>;
  /** @deprecated Use `originContentText`. */
  contentTextForAdmin?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  dislikes: Scalars['Int'];
  duty?: Maybe<MentorDuty>;
  dutyName?: Maybe<Scalars['String']>;
  /** @deprecated Use `contentText`. */
  excerpt?: Maybe<Scalars['String']>;
  fullContentText?: Maybe<Scalars['String']>;
  /** 운영자가 읽지 않은 신고 존재 여부 */
  hasUnreadReport?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ipaddress?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isApprovedMentorComment?: Maybe<Scalars['Boolean']>;
  isAuthor: Scalars['Boolean'];
  isChild: Scalars['Boolean'];
  isMentorComment: Scalars['Boolean'];
  isPostAuthor: Scalars['Boolean'];
  isSecret: Scalars['Boolean'];
  /** 가장 최근에 신고된 날짜 */
  lastReportedAt?: Maybe<Scalars['Date']>;
  likes: Scalars['Int'];
  /** 댓글 작성 당시의 작성자와 게시글의 직무 일치 여부 */
  matchDutyName: Scalars['Boolean'];
  /** 댓글 작성 당시의 작성자와 게시글의 기업 또는 그룹이 일치하는지 여부 */
  matchOrganizationName: Scalars['Boolean'];
  /** 댓글 작성 당시의 작성자와 게시글의 학교가 일치하는지 여부 */
  matchSchoolName: Scalars['Boolean'];
  /** 댓글이 채택된 날짜 */
  menteeAcceptedAt?: Maybe<Scalars['Date']>;
  /** 대댓글 대상인 댓글 */
  mentionedComment?: Maybe<Comment>;
  myRating?: Maybe<RatingType>;
  nickname: Scalars['String'];
  organizationName?: Maybe<Scalars['String']>;
  originContentText?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  postID: Scalars['Int'];
  /** 신고 수 */
  reportCount?: Maybe<Scalars['Int']>;
  /** 사유별 신고 수 */
  reportCountByDescription?: Maybe<Array<CommetAbuseReportLogCountByDescription>>;
  /** 최근 신고사유, 운영자만 조회 가능함 */
  reportDescription?: Maybe<Scalars['String']>;
  /** 신고 로그 */
  reportLogs?: Maybe<CommentAbuseReportLogConnection>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  rewardAmount: Scalars['Int'];
  updatedAt: Scalars['Date'];
  userID?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
  viewerCanAcceptComment: Scalars['Boolean'];
  viewerHasMuteAuthor: Scalars['Boolean'];
};


export type CommentContentTextArgs = {
  pruneLength?: InputMaybe<Scalars['Int']>;
};


export type CommentExcerptArgs = {
  pruneLength?: InputMaybe<Scalars['Int']>;
};


export type CommentReportLogsArgs = {
  filterBy?: InputMaybe<CommentAbuseReportLogFilters>;
  orderBy?: InputMaybe<CommentAbuseReportLogOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type Attachment = {
  __typename?: 'Attachment';
  comment?: Maybe<Comment>;
  id: Scalars['ID'];
  name: Scalars['String'];
  post?: Maybe<Post>;
  size: Scalars['Int'];
  type: Scalars['String'];
  url: Scalars['String'];
};

export type Post = {
  __typename?: 'Post';
  acceptedCommentCount: Scalars['Int'];
  /** 운영자가 신고 내역을 전부 읽은 날짜 */
  adminReportReadAt?: Maybe<Scalars['Date']>;
  allowTrackback?: Maybe<Scalars['Boolean']>;
  attachments: Array<Attachment>;
  author?: Maybe<User>;
  board?: Maybe<Board>;
  commentCount: Scalars['Int'];
  comments: CommentConnection;
  commentsExcludingCommentIds: CommentConnection;
  commentStatus?: Maybe<PostCommentStatus>;
  containsImage: Scalars['Boolean'];
  containsYoutube: Scalars['Boolean'];
  content?: Maybe<Scalars['String']>;
  contentText?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  dislikes: Scalars['Int'];
  duties?: Maybe<Array<MentorDuty>>;
  duty?: Maybe<MentorDuty>;
  dutyName?: Maybe<Scalars['String']>;
  embeddables?: Maybe<Array<Maybe<Embeddable>>>;
  /** @deprecated Use `contentText`. */
  excerpt?: Maybe<Scalars['String']>;
  globalNotice?: Maybe<Scalars['Boolean']>;
  /** 운영자가 읽지 않은 신고 존재 여부 */
  hasUnreadReport?: Maybe<Scalars['Boolean']>;
  /** 로그인한 유저의 채택된 댓글이 있는지에 대한 여부 */
  hasViewerAcceptedCommentInPost: Scalars['Boolean'];
  id: Scalars['ID'];
  imageSrc?: Maybe<Scalars['String']>;
  ipaddress?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isAuthor?: Maybe<Scalars['Boolean']>;
  isHidden: Scalars['Boolean'];
  isMentorPost: Scalars['Boolean'];
  isNotice?: Maybe<Scalars['Boolean']>;
  isScrapped: Scalars['Boolean'];
  /** 로그인한 유저가 게시글의 채택 리워드를 받을 수 있는지 여부 */
  isViewerAvailableAcceptReward: Scalars['Boolean'];
  /** 로그인한 유저가 게시글의 멘토 정보 매칭 리워드를 받을 수 있는지 여부 */
  isViewerAvailableMatchingReward: Scalars['Boolean'];
  /** 로그인한 유저와 게시글의 직무 또는 그룹이 일치하는지 여부 */
  isViewerMatchingMentorDuty: Scalars['Boolean'];
  /** 로그인한 유저와 게시글의 학교가 일치하는지 여부 */
  isViewerMatchingMentorSchool: Scalars['Boolean'];
  /** 로그인한 유저와 게시글의 기업 또는 그룹이 일치하는지 여부 */
  isViewerMatchingOrganization: Scalars['Boolean'];
  /** 가장 최근에 신고된 날짜 */
  lastReportedAt?: Maybe<Scalars['Date']>;
  likes: Scalars['Int'];
  mentorComments: CommentConnection;
  myRating?: Maybe<RatingType>;
  nextPostID?: Maybe<Scalars['ID']>;
  nickname?: Maybe<Scalars['String']>;
  notifyMessage?: Maybe<Scalars['Boolean']>;
  organizationName?: Maybe<Scalars['String']>;
  organizations?: Maybe<Array<Organization>>;
  originContentText?: Maybe<Scalars['String']>;
  postCollections: Array<PostCollection>;
  prevPostID?: Maybe<Scalars['ID']>;
  remainingAcceptableCommentCount?: Maybe<Scalars['Int']>;
  /** 신고 수 */
  reportCount?: Maybe<Scalars['Int']>;
  /** 사유별 신고 수 */
  reportCountByDescription?: Maybe<Array<PostAbuseReportLogCountByDescription>>;
  /** 최근 신고사유, 운영자만 조회 가능함 */
  reportDescription?: Maybe<Scalars['String']>;
  /** 신고 로그 */
  reportLogs?: Maybe<PostAbuseReportLogConnection>;
  schoolName?: Maybe<Scalars['String']>;
  schools?: Maybe<Array<MentorSchool>>;
  score: Scalars['Int'];
  scrapCount: Scalars['Int'];
  status?: Maybe<PostStatus>;
  tags: Array<Tag>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Topic>;
  updatedAt: Scalars['Date'];
  url?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  viewerHasMuteAuthor: Scalars['Boolean'];
  views: Scalars['Int'];
  youtubeSrc?: Maybe<Scalars['String']>;
};


export type PostCommentsArgs = {
  orderBy?: InputMaybe<CommentOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type PostCommentsExcludingCommentIdsArgs = {
  excludingCommentIds: Array<Scalars['ID']>;
  orderBy?: InputMaybe<CommentOrder>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type PostContentTextArgs = {
  pruneLength?: InputMaybe<Scalars['Int']>;
};


export type PostExcerptArgs = {
  pruneLength?: InputMaybe<Scalars['Int']>;
};


export type PostMentorCommentsArgs = {
  orderBy?: InputMaybe<CommentOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type PostNextPostIdArgs = {
  postCursorType?: InputMaybe<PostCursorType>;
};


export type PostPrevPostIdArgs = {
  postCursorType?: InputMaybe<PostCursorType>;
};


export type PostReportLogsArgs = {
  orderBy?: InputMaybe<PostAbuseReportLogOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type Board = {
  __typename?: 'Board';
  anonymity?: Maybe<BoardAnonymity>;
  boardNotices: Array<Post>;
  description?: Maybe<Scalars['String']>;
  footerText?: Maybe<Scalars['String']>;
  globalNotices: Array<Post>;
  hasPermission?: Maybe<Scalars['Boolean']>;
  headerText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isMentorBoard: Scalars['Boolean'];
  menteeAcceptableCommentCount?: Maybe<Scalars['Int']>;
  menu?: Maybe<Menu>;
  module?: Maybe<BoardType>;
  noticeHidden?: Maybe<Scalars['Boolean']>;
  notices: Array<Post>;
  orderDirection?: Maybe<BoardPostOrderDirection>;
  orderField?: Maybe<BoardPostOrderField>;
  permissionGroups: Array<Group>;
  placeholder?: Maybe<Scalars['String']>;
  posts?: Maybe<PostConnection>;
  searchPosts: PostConnection;
  secretPostWritable?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  /** @deprecated use footerText */
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  topics: Array<Topic>;
  useTopic: Scalars['Boolean'];
};


export type BoardHasPermissionArgs = {
  type?: InputMaybe<PermissionType>;
};


export type BoardPermissionGroupsArgs = {
  type: BoardPermissionType;
};


export type BoardPostsArgs = {
  filterBy?: InputMaybe<PostFiltersInput>;
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
};


export type BoardSearchPostsArgs = {
  filterBy?: InputMaybe<SearchPostFromBoardFilters>;
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
};

export enum BoardAnonymity {
  ALL = 'ALL',
  NONE = 'NONE',
  OPTIONAL = 'OPTIONAL'
}

export enum PermissionType {
  ACCESS = 'ACCESS',
  CONSULTATION_READ = 'CONSULTATION_READ',
  LIST = 'LIST',
  MANAGER = 'MANAGER',
  VIEW = 'VIEW',
  WRITE_COMMENT = 'WRITE_COMMENT',
  WRITE_DOCUMENT = 'WRITE_DOCUMENT'
}

export type Menu = Bookmarkable & {
  __typename?: 'Menu';
  board?: Maybe<Board>;
  bookmarkable: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  groupIDList?: Maybe<Scalars['String']>;
  groupIDs: Array<Scalars['ID']>;
  hasNewPost: Scalars['Boolean'];
  id: Scalars['ID'];
  isShortcut: Scalars['Boolean'];
  menuType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parentID?: Maybe<Scalars['String']>;
  permissionViewMenuGroups: Array<Group>;
  slug?: Maybe<Scalars['String']>;
  subMenus: Array<Menu>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  viewerHasBookmarked: Scalars['Boolean'];
};

export type Bookmarkable = {
  id: Scalars['ID'];
  viewerHasBookmarked: Scalars['Boolean'];
};

export enum BoardType {
  BOARD = 'BOARD',
  PAGE = 'PAGE'
}

export enum BoardPostOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum BoardPostOrderField {
  CREATED_AT = 'CREATED_AT'
}

export enum BoardPermissionType {
  ACCESS = 'ACCESS',
  CONSULTATION_READ = 'CONSULTATION_READ',
  LIST = 'LIST',
  MANAGER = 'MANAGER',
  VIEW = 'VIEW',
  WRITE_COMMENT = 'WRITE_COMMENT',
  WRITE_DOCUMENT = 'WRITE_DOCUMENT'
}

export type PostFiltersInput = {
  bestOnly?: InputMaybe<Scalars['Boolean']>;
  boardID?: InputMaybe<Scalars['ID']>;
  commentUserID?: InputMaybe<Scalars['ID']>;
  excludedBoardIDs?: InputMaybe<Array<Scalars['ID']>>;
  field?: InputMaybe<Scalars['String']>;
  globalNotice?: InputMaybe<Scalars['Boolean']>;
  minimumCommentCount?: InputMaybe<Scalars['Int']>;
  minimumLikeCount?: InputMaybe<Scalars['Int']>;
  minimumViewCount?: InputMaybe<Scalars['Int']>;
  onlyDelete?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  scrapCount?: InputMaybe<Scalars['Int']>;
  tagID?: InputMaybe<Scalars['ID']>;
  topicID?: InputMaybe<Scalars['ID']>;
  userID?: InputMaybe<Scalars['ID']>;
  views?: InputMaybe<RangeInput>;
  withDelete?: InputMaybe<Scalars['Boolean']>;
  word?: InputMaybe<Scalars['String']>;
};

export type RangeInput = {
  gte?: InputMaybe<Scalars['Int']>;
};

export type PostOrder = {
  direction: OrderDirection;
  field: PostOrderField;
};

export enum PostOrderField {
  COMMENT_COUNT = 'COMMENT_COUNT',
  CREATED_AT = 'CREATED_AT',
  GLOBAL_NOTICE = 'GLOBAL_NOTICE',
  ID = 'ID',
  LIKES = 'LIKES',
  RELEVANCE = 'RELEVANCE',
  SCORE = 'SCORE',
  SCRAP_COUNT = 'SCRAP_COUNT',
  UPDATED_AT = 'UPDATED_AT',
  VIEWS = 'VIEWS'
}

export type PostConnection = {
  __typename?: 'PostConnection';
  nodes?: Maybe<Array<Post>>;
  totalCount: Scalars['Int'];
};

export type SearchPostFromBoardFilters = {
  dutyId?: InputMaybe<Scalars['ID']>;
  dutyName?: InputMaybe<Scalars['String']>;
  field?: InputMaybe<SearchPostFromBoardWordField>;
  organizationName?: InputMaybe<Scalars['String']>;
  schoolName?: InputMaybe<Scalars['String']>;
  word?: InputMaybe<Scalars['String']>;
};

export enum SearchPostFromBoardWordField {
  CONTENT = 'CONTENT',
  NICK_NAME = 'NICK_NAME',
  TITLE = 'TITLE',
  TITLE_WITH_CONTENT = 'TITLE_WITH_CONTENT'
}

export type Topic = {
  __typename?: 'Topic';
  board?: Maybe<Board>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  rank: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CommentOrder = {
  direction: OrderDirection;
  field: CommentOrderField;
};

export enum CommentOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  LIKES = 'LIKES'
}

export enum PostCommentStatus {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export type Embeddable = {
  __typename?: 'Embeddable';
  id: Scalars['ID'];
  jsonld?: Maybe<EmbeddableJsonLd>;
  type: EmbeddableType;
  uri: Scalars['String'];
};

export type EmbeddableJsonLd = {
  _id: Scalars['String'];
  _type: EmbeddableJsonLdType;
};

export enum EmbeddableJsonLdType {
  CBT_EXAM = 'CbtExam'
}

export type EmbeddableType = {
  __typename?: 'EmbeddableType';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  jsonldType: EmbeddableJsonLdType;
  showButtonOnEditor: Scalars['Boolean'];
};

export enum RatingType {
  CANCEL = 'CANCEL',
  DISLIKE = 'DISLIKE',
  LIKE = 'LIKE'
}

export enum PostCursorType {
  ALL = 'ALL',
  BEST = 'BEST',
  BOARD = 'BOARD'
}

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** 관리자만 이용 가능한 서비스 입니다. */
  organizationGroup?: Maybe<OrganizationGroup>;
};

export type OrganizationGroup = {
  __typename?: 'OrganizationGroup';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  organizations: OrganizationConnection;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  nodes: Array<Organization>;
  totalCount: Scalars['Int'];
};

export type PostCollection = {
  __typename?: 'PostCollection';
  automation?: Maybe<PostCollectionAutomation>;
  id: Scalars['ID'];
  posts: Array<Post>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};


export type PostCollectionPostsArgs = {
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
};

export type PostCollectionAutomation = {
  __typename?: 'PostCollectionAutomation';
  boards: Array<Board>;
  enabled: Scalars['Boolean'];
  filters?: Maybe<PostFilters>;
};

export type PostFilters = {
  __typename?: 'PostFilters';
  globalNotice?: Maybe<Scalars['Boolean']>;
  views?: Maybe<Range>;
};

export type Range = {
  __typename?: 'Range';
  gte?: Maybe<Scalars['Int']>;
};

export type PostAbuseReportLogCountByDescription = {
  __typename?: 'PostAbuseReportLogCountByDescription';
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type PostAbuseReportLogOrder = {
  direction: OrderDirection;
  field: PostAbuseReportLogOrderField;
};

export enum PostAbuseReportLogOrderField {
  ADMIN_READ_AT = 'ADMIN_READ_AT',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type PostAbuseReportLogConnection = {
  __typename?: 'PostAbuseReportLogConnection';
  nodes?: Maybe<Array<PostAbuseReportLog>>;
  totalCount: Scalars['Int'];
};

export type PostAbuseReportLog = {
  __typename?: 'PostAbuseReportLog';
  /** 운영자가 확인한 시간 */
  adminReadAt?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ipaddress?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  /** 신고한 회원 */
  reporter?: Maybe<User>;
};

export type MentorSchool = {
  __typename?: 'MentorSchool';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum PostStatus {
  PUBLIC = 'PUBLIC',
  TEMP = 'TEMP'
}

export type Tag = {
  __typename?: 'Tag';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CommetAbuseReportLogCountByDescription = {
  __typename?: 'CommetAbuseReportLogCountByDescription';
  count?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type CommentAbuseReportLogFilters = {
  commentID?: InputMaybe<Scalars['ID']>;
};

export type CommentAbuseReportLogOrder = {
  direction: OrderDirection;
  field: CommentAbuseReportLogOrderField;
};

export enum CommentAbuseReportLogOrderField {
  ADMIN_READ_AT = 'ADMIN_READ_AT',
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type CommentAbuseReportLogConnection = {
  __typename?: 'CommentAbuseReportLogConnection';
  nodes?: Maybe<Array<CommentAbuseReportLog>>;
  totalCount: Scalars['Int'];
};

export type CommentAbuseReportLog = {
  __typename?: 'CommentAbuseReportLog';
  /** 운영자가 확인한 시간 */
  adminReadAt?: Maybe<Scalars['Date']>;
  comment?: Maybe<Comment>;
  createdAt?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  ipaddress?: Maybe<Scalars['String']>;
  /** 신고한 회원 */
  reporter?: Maybe<User>;
};

export type MentorRewardLogFilters = {
  /** 리워드 사용 또는 지급 일자 범위 */
  dateRange?: InputMaybe<DateRange>;
  /** 리워드 사용/지급 여부 (null or undefined인 경우 전체 조회) */
  mentorRewardLogType?: InputMaybe<MentorRewardLogType>;
  /** 리워드 지급 댓글 내용 검색어 */
  query?: InputMaybe<Scalars['String']>;
};

export enum MentorRewardLogType {
  GRANT_POINT = 'GRANT_POINT',
  USE_POINT = 'USE_POINT'
}

export type MentorRewardLogConnection = {
  __typename?: 'MentorRewardLogConnection';
  nodes: Array<MentorRewardLog>;
  totalCount: Scalars['Int'];
};

export type MentorRewardLog = {
  __typename?: 'MentorRewardLog';
  amount: Scalars['Int'];
  comment?: Maybe<Comment>;
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  reason: RewardReason;
  user: User;
  userRewardAmount: Scalars['Int'];
};

export enum RewardReason {
  ACCEPTED_COMMENT = 'ACCEPTED_COMMENT',
  DUTY_NAME_MATCHED_COMMENT = 'DUTY_NAME_MATCHED_COMMENT',
  MONTHLY_POINT_EXPIRES = 'MONTHLY_POINT_EXPIRES',
  ORGANIZATION_NAME_MATCHED_COMMENT = 'ORGANIZATION_NAME_MATCHED_COMMENT',
  REQUEST_CASHOUT = 'REQUEST_CASHOUT',
  SCHOOL_NAME_MATCHED_COMMENT = 'SCHOOL_NAME_MATCHED_COMMENT'
}

export type Sitemap = {
  __typename?: 'Sitemap';
  children: Array<IMenu>;
  id: Scalars['ID'];
  listOrder: Scalars['String'];
  /** 사이트맵의 이름 */
  title?: Maybe<Scalars['String']>;
};

export type BanLogsFilter = {
  nickname?: InputMaybe<Scalars['String']>;
  userID?: InputMaybe<Scalars['ID']>;
};

export type BanLogsOrder = {
  direction: OrderDirection;
  field: BanLogsOrderField;
};

export enum BanLogsOrderField {
  CREATED_AT = 'CREATED_AT',
  END_AT = 'END_AT',
  ID = 'ID',
  START_AT = 'START_AT'
}

export type CollectionRequirement = {
  __typename?: 'CollectionRequirement';
  comment: Scalars['Int'];
  createdAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  like: Scalars['Int'];
  scrap: Scalars['Int'];
  slug: Scalars['String'];
  view: Scalars['Int'];
};

export type BestPostListInput = {
  /** Use type and pagination */
  endDate?: InputMaybe<Scalars['String']>;
  /** Use type and pagination */
  minimumCommentCount?: InputMaybe<Scalars['Int']>;
  /** Use type and pagination */
  minimumLikeCount?: InputMaybe<Scalars['Int']>;
  /** Use type and pagination */
  minimumViewCount?: InputMaybe<Scalars['Int']>;
  /** Use type and pagination */
  order?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<Pagination>;
  /** Use type and pagination */
  startDate?: InputMaybe<Scalars['String']>;
  /** Use non-null assertion operator */
  type?: InputMaybe<BestPostListType>;
};

export enum BestPostListType {
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  RECENT = 'RECENT',
  REPLY_COUNT = 'REPLY_COUNT',
  VOTE_COUNT = 'VOTE_COUNT'
}

export type BestPostListPayload = {
  __typename?: 'BestPostListPayload';
  bestPosts?: Maybe<Array<Post>>;
};

export enum BoardTab {
  BEST = 'BEST',
  COMMUNITY = 'COMMUNITY',
  NOTICE = 'NOTICE'
}

export type BoardNavigation = {
  __typename?: 'BoardNavigation';
  board?: Maybe<Board>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BoardNavigationFiltersInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type BoardNavigationConnection = {
  __typename?: 'BoardNavigationConnection';
  nodes: Array<BoardNavigation>;
  totalCount: Scalars['Int'];
};

export type BoardFiltersInput = {
  permissionType?: InputMaybe<PermissionType>;
  q?: InputMaybe<Scalars['String']>;
};

export type BoardConnection = {
  __typename?: 'BoardConnection';
  nodes: Array<Board>;
  totalCount: Scalars['Int'];
};

export type BookmarkFilter = {
  isMentorBoard?: InputMaybe<Scalars['Boolean']>;
};

export type BookmarkOrder = {
  direction: OrderDirection;
  field: BookmarkOrderField;
};

export enum BookmarkOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type BookmarkableConnection = {
  __typename?: 'BookmarkableConnection';
  nodes: Array<Bookmarkable>;
  totalCount: Scalars['Int'];
};

export type CommentFiltersInput = {
  hasChildren?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  parentID?: InputMaybe<Scalars['ID']>;
  userID?: InputMaybe<Scalars['ID']>;
};

export type Config = {
  __typename?: 'Config';
  defaultValue?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Ad = {
  __typename?: 'Ad';
  campaignID?: Maybe<Scalars['ID']>;
  campaignLandingURL?: Maybe<Scalars['String']>;
  defaultImageURL?: Maybe<Scalars['String']>;
  defaultLandingURL?: Maybe<Scalars['String']>;
  imageURL?: Maybe<Scalars['String']>;
  isPlacementActive?: Maybe<Scalars['Boolean']>;
  placementHeight: Scalars['Int'];
  placementID: Scalars['ID'];
  placementWidth: Scalars['Int'];
};

export type UserScrap = {
  __typename?: 'UserScrap';
  createdAt?: Maybe<Scalars['Date']>;
  deletedAt?: Maybe<Scalars['Date']>;
  externalID?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOrder?: Maybe<Scalars['Int']>;
  post?: Maybe<Post>;
  postID: Scalars['ID'];
  userID: Scalars['ID'];
};

export type CurrentUserScrapsFilter = {
  postID?: InputMaybe<Scalars['ID']>;
  q?: InputMaybe<Scalars['String']>;
};

export type UserScrapOrder = {
  direction: OrderDirection;
  field: UserScrapOrderField;
};

export enum UserScrapOrderField {
  CREATED_AT = 'CREATED_AT',
  LIST_ORDER = 'LIST_ORDER'
}

export type CurrentUserScrapConnection = {
  __typename?: 'CurrentUserScrapConnection';
  nodes: Array<UserScrap>;
  totalCount: Scalars['Int'];
};

export type DailyCommentByUserFilters = {
  dateAt?: InputMaybe<Scalars['String']>;
};

export type DailyCommentByUserOrder = {
  direction: OrderDirection;
  field: DailyCommentByUserOrderField;
};

export enum DailyCommentByUserOrderField {
  ID = 'ID'
}

export type DailyCommentByUserConnection = {
  __typename?: 'DailyCommentByUserConnection';
  nodes?: Maybe<Array<Comment>>;
  totalCount: Scalars['Int'];
};

export type DailyPostByUserFilters = {
  dateAt?: InputMaybe<Scalars['String']>;
};

export type DailyPostByUserOrder = {
  direction: OrderDirection;
  field: DailyPostByUserOrderField;
};

export enum DailyPostByUserOrderField {
  ID = 'ID'
}

export type DailyPostByUserConnection = {
  __typename?: 'DailyPostByUserConnection';
  nodes?: Maybe<Array<Post>>;
  totalCount: Scalars['Int'];
};

export type EmbeddablePostFilter = {
  uri: Scalars['String'];
};

export type EmbeddablePostOrder = {
  direction: OrderDirection;
  field: EmbeddablePostOrderField;
};

export enum EmbeddablePostOrderField {
  ID = 'ID',
  LAST_UPDATED_AT = 'LAST_UPDATED_AT',
  VIEW_COUNT = 'VIEW_COUNT'
}

/** 그룹들을 검색할 때 보낼 수 있는 객체 */
export type GroupFilters = {
  q?: InputMaybe<Scalars['String']>;
};

/** 그룹들을 검색할 때 반환하는 객체 */
export type GroupConnection = {
  __typename?: 'GroupConnection';
  nodes: Array<Group>;
  totalCount: Scalars['Int'];
};

export type RewardAmountSettings = {
  __typename?: 'RewardAmountSettings';
  createdAt: Scalars['Date'];
  /** 채택된 댓글에 대한 지급 금액 */
  forAcceptedComment: Scalars['Int'];
  /** 직무명이 일치하는 댓글에 대한 지급 금액 */
  forDutyNameMatchedComment: Scalars['Int'];
  /** 기업명이 일치히는 댓글에 대한 지급 금액 */
  forOrganizationNameMatchedComment: Scalars['Int'];
  /** 학교명이 일치하는 댓글에 대한 지급 금액 */
  forSchoolNameMatchedComment: Scalars['Int'];
  id: Scalars['ID'];
  updatedAt?: Maybe<Scalars['Date']>;
};

export type RewardAmountSettingsChangeLogConnection = {
  __typename?: 'RewardAmountSettingsChangeLogConnection';
  nodes: Array<RewardAmountSettingsChangeLog>;
  totalCount: Scalars['Int'];
};

export type RewardAmountSettingsChangeLog = {
  __typename?: 'RewardAmountSettingsChangeLog';
  /** 변경 후 리워드 금액 */
  afterAmount: Scalars['Int'];
  /** 변경 전 리워드 금액 */
  beforeAmount: Scalars['Int'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  /** 리워드 지급 사유 */
  rewardReason: RewardReason;
};

export type MentorUserFilters = {
  mentorApprovedAtRange?: InputMaybe<DateRange>;
  mentorAuthenticationRequestedAtRange?: InputMaybe<DateRange>;
  mentorAuthenticationStatus?: InputMaybe<SearchMentorAuthenticationStatus>;
  query?: InputMaybe<Scalars['String']>;
  queryField?: InputMaybe<MentorUserQueryField>;
};

export enum SearchMentorAuthenticationStatus {
  APPROVED_OR_REJECTED = 'APPROVED_OR_REJECTED',
  PENDING = 'PENDING',
  PENDING_OR_UPDATE_IN_REVIEW = 'PENDING_OR_UPDATE_IN_REVIEW',
  UPDATE_IN_REVIEW = 'UPDATE_IN_REVIEW'
}

export enum MentorUserQueryField {
  DUTY_NAME = 'DUTY_NAME',
  DUTY_NAME_IN_REVIEW = 'DUTY_NAME_IN_REVIEW',
  MENTOR_NICKNAME = 'MENTOR_NICKNAME',
  MENTOR_REAL_NAME = 'MENTOR_REAL_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ORGANIZATION_NAME_IN_REVIEW = 'ORGANIZATION_NAME_IN_REVIEW',
  SCHOOL_NAME = 'SCHOOL_NAME',
  SCHOOL_NAME_IN_REVIEW = 'SCHOOL_NAME_IN_REVIEW'
}

export type MentorUserOrders = {
  direction: OrderDirection;
  field: MentorUserOrderField;
};

export enum MentorUserOrderField {
  ACCEPTED_COMMENT_COUNT = 'ACCEPTED_COMMENT_COUNT',
  MENTOR_APPROVED_AT = 'MENTOR_APPROVED_AT',
  MENTOR_AUTHENTICATION_DOCUMENT_REQUESTED_AT = 'MENTOR_AUTHENTICATION_DOCUMENT_REQUESTED_AT',
  MENTOR_COMMENT_COUNT = 'MENTOR_COMMENT_COUNT',
  MENTOR_REWARD_AMOUNT = 'MENTOR_REWARD_AMOUNT'
}

export type MentorUserKpiFilters = {
  dateRange: DateRange;
};

export type MentorUserKpiGroups = {
  period: Period;
};

export enum Period {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY'
}

export type MentorUserKpiConnection = {
  __typename?: 'MentorUserKpiConnection';
  nodes: Array<MentorUserKpi>;
  totalCount: Scalars['Int'];
};

export type MentorUserKpi = {
  __typename?: 'MentorUserKpi';
  acceptedCommentCount: Scalars['Int'];
  activeMentorUserCount: Scalars['Int'];
  createdAt: Scalars['Date'];
  date: Scalars['Date'];
  dutyNameMatchedCommentCount: Scalars['Int'];
  id: Scalars['ID'];
  mentorCommentCount: Scalars['Int'];
  newMentorUserCount: Scalars['Int'];
  organizationNameMatchedCommentCount: Scalars['Int'];
  schoolNameMatchedCommentCount: Scalars['Int'];
};

/** 메뉴들을 검색할 때 보낼 수 있는 객체 */
export type MenuFilters = {
  q?: InputMaybe<Scalars['String']>;
};

/** 메뉴들을 검색할 때 반환하는 객체 */
export type MenuConnection = {
  __typename?: 'MenuConnection';
  nodes: Array<IMenu>;
  totalCount: Scalars['Int'];
};

export type Message = {
  __typename?: 'Message';
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  excerpt?: Maybe<Scalars['String']>;
  hasRead: Scalars['Boolean'];
  id: Scalars['ID'];
  isReplied?: Maybe<Scalars['Boolean']>;
  linkURL?: Maybe<Scalars['String']>;
  notificationType?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  readAt?: Maybe<Scalars['Date']>;
  receivedMessageID: Scalars['Int'];
  receiver?: Maybe<User>;
  receiverID: Scalars['Int'];
  sender?: Maybe<User>;
  senderID: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};


export type MessageExcerptArgs = {
  pruneLength?: InputMaybe<Scalars['Int']>;
};

export type MessageOrder = {
  direction: OrderDirection;
  field: MessageOrderField;
};

export enum MessageOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID'
}

export type MessageConnection = {
  __typename?: 'MessageConnection';
  nodes?: Maybe<Array<Message>>;
  totalCount: Scalars['Int'];
};

export type MessageFiltersInput = {
  type: Scalars['String'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  nodes?: Maybe<Array<Maybe<Notification>>>;
  notifications?: Maybe<Array<Maybe<Notification>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Notification = {
  __typename?: 'Notification';
  content: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  hasRead: Scalars['Boolean'];
  id: Scalars['ID'];
  linkURL: Scalars['String'];
  receiverID?: Maybe<Scalars['Int']>;
  senderID?: Maybe<Scalars['Int']>;
  senderNickname?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** 기업 그룹 검색시 필터링 할 때 사용하는 객체 */
export type OrganizationGroupFilters = {
  /** 검색 필드 */
  field?: InputMaybe<OrganizationGroupQueryField>;
  /** 검색어 */
  query?: InputMaybe<Scalars['String']>;
};

export enum OrganizationGroupQueryField {
  ORGANIZATION_GROUP_NAME = 'ORGANIZATION_GROUP_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME'
}

export type OrganizationGroupConnection = {
  __typename?: 'OrganizationGroupConnection';
  nodes: Array<OrganizationGroup>;
  totalCount: Scalars['Int'];
};

/** 기업 검색시 필터링 할 때 사용하는 객체 */
export type OrganizationsAddedByAdminFiltersInput = {
  /** 검색 필드 */
  field?: InputMaybe<OrganizationAddedByAdminQueryField>;
  /** 검색어 */
  query?: InputMaybe<Scalars['String']>;
};

export enum OrganizationAddedByAdminQueryField {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME'
}

export type PostAbuseReportLogFilters = {
  postID?: InputMaybe<Scalars['ID']>;
};

export type PostCollectionFiltersInput = {
  q?: InputMaybe<Scalars['String']>;
};

export type PostCollectionOrder = {
  direction: OrderDirection;
  field: PostCollectionOrderField;
};

export enum PostCollectionOrderField {
  ID = 'ID'
}

export type PostCollectionConnection = {
  __typename?: 'PostCollectionConnection';
  nodes?: Maybe<Array<Maybe<PostCollection>>>;
  totalCount: Scalars['Int'];
};

export type PostSearchFiltersInput = {
  boardSlugs?: InputMaybe<Array<Scalars['String']>>;
  minimumShouldMatch?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
};

export type PostSearchOrderInput = {
  direction: OrderDirection;
  field: PostSearchOrderField;
};

export enum PostSearchOrderField {
  CREATED_AT = 'CREATED_AT',
  RELEVANCE = 'RELEVANCE',
  VIEW_COUNT = 'VIEW_COUNT'
}

export type PostSearchResultConnection = {
  __typename?: 'PostSearchResultConnection';
  nodes?: Maybe<Array<Maybe<PostSearchResult>>>;
  totalCount: Scalars['Int'];
};

export type PostSearchResult = {
  __typename?: 'PostSearchResult';
  score?: Maybe<Scalars['Float']>;
  source?: Maybe<Post>;
};

export type CommentReportFilters = {
  /** 내용 */
  contentText?: InputMaybe<Scalars['String']>;
  /** 신고날짜 종료일 */
  endDate?: InputMaybe<Scalars['Date']>;
  /** 링커리어 사이트에 등록된 닉네임 */
  nickname?: InputMaybe<Scalars['String']>;
  /** 안읽은 신고 내역 있는지 여부 */
  reportReadStatus?: InputMaybe<ReportReadStatus>;
  /** 신고날짜 시작일 */
  startDate?: InputMaybe<Scalars['Date']>;
  /** 회원 아이디 */
  userID?: InputMaybe<Scalars['ID']>;
};

export enum ReportReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export type CommentReportOrder = {
  direction: OrderDirection;
  field: CommentReportOrderField;
};

export enum CommentReportOrderField {
  DELETED_AT = 'DELETED_AT',
  ID = 'ID',
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

export type PostReportFilters = {
  /** 신고날짜 종료일 */
  endDate?: InputMaybe<Scalars['Date']>;
  /** 링커리어 사이트에 등록된 닉네임 */
  nickname?: InputMaybe<Scalars['String']>;
  /** 안읽은 신고 내역 있는지 여부 */
  reportReadStatus?: InputMaybe<ReportReadStatus>;
  /** 신고날짜 시작일 */
  startDate?: InputMaybe<Scalars['Date']>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 회원 아이디 */
  userID?: InputMaybe<Scalars['ID']>;
};

export type PostReportOrder = {
  direction: OrderDirection;
  field: PostReportOrderField;
};

export enum PostReportOrderField {
  DELETED_AT = 'DELETED_AT',
  ID = 'ID',
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

export type RequestCashoutLogFilters = {
  /** 현금 지급 신청 일자 범위 */
  dateRange?: InputMaybe<DateRange>;
  /** 멘토 닉네임 검색 쿼리 */
  query?: InputMaybe<Scalars['String']>;
};

export type RequestCashoutLogConnection = {
  __typename?: 'RequestCashoutLogConnection';
  nodes: Array<RequestCashoutLog>;
  totalCount: Scalars['Int'];
};

export type RequestCashoutLog = {
  __typename?: 'RequestCashoutLog';
  amount: Scalars['Int'];
  bank: Bank;
  bankAccountNumber: Scalars['String'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  phoneNumber: Scalars['String'];
  realName: Scalars['String'];
  user?: Maybe<User>;
};

export type RewardKpiFilters = {
  dateRange: DateRange;
};

export type RewardKpiGroups = {
  period: Period;
};

export type RewardKpiConnection = {
  __typename?: 'RewardKpiConnection';
  nodes: Array<RewardKpi>;
  totalCount: Scalars['Int'];
};

export type RewardKpi = {
  __typename?: 'RewardKpi';
  createdAt: Scalars['Date'];
  date: Scalars['Date'];
  id: Scalars['ID'];
  totalCashoutRequestAmount: Scalars['Int'];
  totalRewardAmount: Scalars['Int'];
  totalRewardAmountForAcceptedComments: Scalars['Int'];
  totalRewardAmountForDutyNameMatchedComments: Scalars['Int'];
  totalRewardAmountForOrgNameMatchedComments: Scalars['Int'];
  totalRewardAmountForSchoolNameMatchedComments: Scalars['Int'];
};

export type SearchMentorDutiesFilters = {
  name: Scalars['String'];
  /** 마지막 depth의 데이터만 가져오는지에 대한 여부 */
  retrieveLastDepthData?: InputMaybe<Scalars['Boolean']>;
};

/** 사이트맵들을 검색할 때 보낼 수 있는 객체 */
export type SitemapFiltersInput = {
  /** 해당 문자열을 포함하는 사이트맵들을 검색 */
  q?: InputMaybe<Scalars['String']>;
};

/** 사이트맵들을 검색할 때 반환하는 객체 */
export type SitemapConnection = {
  __typename?: 'SitemapConnection';
  nodes: Array<Sitemap>;
  totalCount: Scalars['Int'];
};

export type TagFilters = {
  q?: InputMaybe<Scalars['String']>;
};

export type TagOrder = {
  direction: OrderDirection;
  field: TagOrderField;
};

export enum TagOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  NAME = 'NAME',
  UPDATED_AT = 'UPDATED_AT'
}

export type TagConnection = {
  __typename?: 'TagConnection';
  nodes: Array<Tag>;
  totalCount: Scalars['Int'];
};

export type Trash = {
  __typename?: 'Trash';
  createdAt: Scalars['Date'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ipAddrress?: Maybe<Scalars['String']>;
  originModule?: Maybe<Scalars['String']>;
  serializedObject?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['Int']>;
};

export type CommentTrashFilters = {
  /** 내용 */
  contentText?: InputMaybe<Scalars['String']>;
  /** 신고날짜 종료일 */
  endDate?: InputMaybe<Scalars['Date']>;
  /** 링커리어 사이트에 등록된 닉네임 */
  nickname?: InputMaybe<Scalars['String']>;
  /** 신고날짜 시작일 */
  startDate?: InputMaybe<Scalars['Date']>;
  /** 회원 아이디 */
  userID?: InputMaybe<Scalars['ID']>;
};

export type CommentTrashOrder = {
  direction: OrderDirection;
  field: CommentTrashOrderField;
};

export enum CommentTrashOrderField {
  DELETED_AT = 'DELETED_AT',
  ID = 'ID',
  LAST_REPORTED_AT = 'LAST_REPORTED_AT'
}

export type PostTrashFilters = {
  /** 신고날짜 종료일 */
  endDate?: InputMaybe<Scalars['Date']>;
  /** 링커리어 사이트에 등록된 닉네임 */
  nickname?: InputMaybe<Scalars['String']>;
  /** 신고날짜 시작일 */
  startDate?: InputMaybe<Scalars['Date']>;
  /** 제목 */
  title?: InputMaybe<Scalars['String']>;
  /** 회원 아이디 */
  userID?: InputMaybe<Scalars['ID']>;
};

export type PostTrashOrder = {
  direction: OrderDirection;
  field: PostTrashOrderField;
};

export enum PostTrashOrderField {
  DELETED_AT = 'DELETED_AT',
  ID = 'ID',
  LAST_REPORTED_AT = 'LAST_REPORTED_AT'
}

export type TrashConnection = {
  __typename?: 'TrashConnection';
  nodes: Array<Trash>;
  totalCount: Scalars['Int'];
};

export type ViewerRecommendedMentorPostFilters = {
  /** 직무명 일치 여부 */
  matchDutyName?: InputMaybe<Scalars['Boolean']>;
  /** 기업명 일치 여부 */
  matchOrganizationName?: InputMaybe<Scalars['Boolean']>;
  /** 학교명 일치 여부 */
  matchSchoolName?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptMentorComment: AcceptMentorCommentPayload;
  addBookmark?: Maybe<AddBookmarkPayload>;
  attachmentCompleteUpload?: Maybe<AttachmentCompleteUploadPayload>;
  attachmentDelete?: Maybe<AttachmentDeletePayload>;
  attachmentPrepareUpload?: Maybe<AttachmentPrepareUploadPayload>;
  authenticateEmail: AuthenticateEmailPayload;
  authenticateWithDocuments: AuthenticateWithDocumentsPayloadDto;
  authenticateWithEmail: AuthenticateWithEmailPayload;
  banUser?: Maybe<BanLog>;
  boardCreate: BoardCreatePayload;
  boardDelete: BoardDeletePayload;
  /**
   * 관리자 전용
   *
   * 게시판 메뉴를 생성한다.
   *
   * sitemapID만 주어질 경우 sitemap 아래에, parentID만 주어질 경우 menu 아래에 생성된다.
   *
   * 성공 시 생성된 메뉴를 반환한다.
   *
   * sitemapID와 parentID 둘 다 입력하지 않았을 시, 또는 둘 다 입력했을 시 Exception.
   *
   * 해당 boardSlug를 가진 게시판이 없을 경우 Exception.
   *
   * 실패 시 Exception.
   */
  boardMenuCreate: BoardMenuCreatePayload;
  /**
   * 관리자 전용
   *
   * 게시판 메뉴를 병합한다.
   *
   * 성공 시 병합된 메뉴를 반환한다.
   *
   * 소스 메뉴는 병합이후 삭제된다.
   *
   * 소스 메뉴와 타겟 메뉴가 같거나 없는 경우 Exception.
   *
   * 소스 메뉴와 타켓 메뉴가 board 메뉴가 아닐 경우 Exception.
   *
   * 소스 메뉴와 타겟 메뉴의 게시판이 없는 경우 Exception.
   *
   * 실패시 Exception.
   */
  boardMenuMerge: BoardMenuMergePayload;
  /**
   * 관리자 전용
   *
   * 게시판 메뉴를 수정한다.
   *
   * 성공 시 수정된 메뉴를 반환한다.
   *
   * 해당 id를 가진 바로가기 메뉴가 없다면 null을 반환한다.
   *
   * 해당 boardSlug를 가진 게시판이 없을 경우 Exception.
   *
   * 실패 시 Exception.
   */
  boardMenuUpdate: BoardMenuUpdatePayload;
  /**
   * 관리자 전용
   *
   * 게시판을 병합한다.
   *
   * 성공 시 병합된 게시판을 반환한다.
   *
   * 소스 게시판은 병합이후 삭제된다.
   *
   * 소스 게시판과 타겟 게시판이 같거나 없는 경우 Exception.
   *
   * 소스 게시판 또는 타겟 게시판이 없는 경우 Exception.
   *
   * 실패시 Exception.
   */
  boardMerge: BoardMergePayload;
  boardNavigationUpdate?: Maybe<BoardNavigationUpdatePayload>;
  boardUpdate: BoardUpdatePayload;
  commentAbuseReport?: Maybe<Scalars['Boolean']>;
  commentCreate?: Maybe<CommentCreatePayload>;
  commentDelete?: Maybe<CommentDeletePayload>;
  commentDislike?: Maybe<CommentDislikePayload>;
  commentLike?: Maybe<CommentLikePayload>;
  commentRate?: Maybe<CommentRatePayload>;
  commentRemoveLike?: Maybe<CommentRemoveLikePayload>;
  commentUpdate?: Maybe<CommentUpdatePayload>;
  configReset?: Maybe<Scalars['Boolean']>;
  configUpdate?: Maybe<Config>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  createMentorBoard: CreateMentorBoardPayload;
  createMentorComment: CreateMentorCommentPayload;
  /** 멘토 게시글 작성시 내용 및 답변받고 싶은 현직자 정보를 생성 */
  createMentorPost: CreateMentorPostPayload;
  /**
   * 관리자만 이용 가능한 서비스 입니다.
   *
   * 기업 생성
   */
  createOrganization: CreateOrganizationPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  createOrganizationGroup: CreateOrganizationGroupPayload;
  currentUserScrapCreate?: Maybe<CurrentUserScrapCreatePayload>;
  currentUserScrapDelete?: Maybe<CurrentUserScrapDeletePayload>;
  currentUserScrapsDeleteAll?: Maybe<CurrentUserScrapsDeleteAllPayload>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  deleteMentorBoard: DeleteMentorBoardPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  deleteMentorComment: DeleteMentorCommentPayload;
  deleteMentorDocument: DeleteMentorDocumentPayload;
  /** 게시글 작성자가 멘토 게시글 삭제 */
  deleteMentorPost: DeleteMentorPostPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  deleteOrganizationGroup: DeleteOrganizationGroupPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  duplicateCheckOrganizationGroupName: Scalars['Boolean'];
  /**
   * 그룹을 생성한다.
   *
   * 성공 시 생성된 그룹을 반환한다.
   *
   * 실패 시 Exception.
   */
  groupCreate: GroupCreatePayload;
  /**
   * 그룹을 삭제한다.
   *
   * 성공 시 삭제된 그룹을 반환한다.
   *
   * 해당 id를 가진 그룹이 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  groupDelete: GroupDeletePayload;
  /**
   * 그룹을 수정한다.
   *
   * 성공 시 수정된 그룹을 반환한다.
   *
   * 해당 id를 가진 그룹이 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  groupUpdate: GroupUpdatePayload;
  /**
   * 게시글 리스트 및 상세페이지에서 게시글을 노출시키지 않습니다.
   * 단 작성자는 url을 통해 확인할 수 있습니다.
   * (2025.3.7) expiredAt은 사용되지 않습니다.
   */
  hidePostFromOthers?: Maybe<HidePostPayload>;
  issueSignedURL?: Maybe<Scalars['String']>;
  /**
   * 관리자 전용
   *
   * menu 쿼리가 반환할 메인 메뉴를 설정합니다.
   *
   * 성공 시 설정된 메뉴를 반환합니다.
   *
   * 입력 id를 가진 메뉴가 없다면 null을 반환합니다.
   *
   * 실패 시 Exception.
   */
  mainMenuSet?: Maybe<IMenu>;
  mentorDocumentCompleteUpload: MentorDocumentCompleteUploadPayload;
  mentorDocumentPrepareUpload: MentorDocumentPrepareUploadPayload;
  /**
   * 관리자 전용
   *
   * 메뉴와 그 밑의 메뉴들, 그리고 연결된 모든 게시판을 삭제한다.
   *
   * 성공 시 삭제하기로 고른 메뉴를 반환한다.
   *
   * 해당 id를 가진 메뉴가 없다면 null을 반환한다.
   *
   * 실패 시 Exception
   */
  menuAndBoardDelete: MenuDeletePayload;
  /**
   * 관리자 전용
   *
   * 메뉴를 삭제한다.
   *
   * 성공 시 삭제된 메뉴를 반환한다.
   *
   * 해당 id를 가진 메뉴가 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  menuDelete: MenuDeletePayload;
  /**
   * 해당 메뉴를 복사해 새로 생성합니다.
   *
   * 게시판 메뉴의 경우 연결되어있는 게시판도 동일한 설정의 게시판이 생성됩니다.
   *
   * sitemap 아래에 생성할 경우 sitemapID만을, menu 아래에 생성할 경우 parentID만을 입력해주세요.
   *
   * 관리자 전용
   */
  menuDuplicate: MenuDuplicatePayload;
  /**
   * 해당 메뉴를 1순위 먼저 나오게(direction = UP), 또는 1순위 나중에 나오게(direction = DOWN) 변경합니다.
   *
   * 변경한 메뉴와 그 변경으로 인해 순위가 변경된 또 다른 한개의 메뉴를 반환합니다.
   *
   * 해당 id를 가진 메뉴가 없다면 payload 내부에 null을 반환합니다.
   *
   * 관리자 전용
   */
  menuMove: MenuMovePayload;
  messageCreate?: Maybe<MessageCreatePayload>;
  messageDelete?: Maybe<MessageDeletePayload>;
  messageDeleteAll?: Maybe<MessageDeleteAllPayload>;
  messageStore?: Maybe<MessageStorePayload>;
  messageUpdate?: Maybe<MessageUpdatePayload>;
  muteUser?: Maybe<User>;
  normalizeListOrder: Scalars['Boolean'];
  notificationCreate?: Maybe<NotificationCreatePayload>;
  notificationDelete?: Maybe<NotificationDeletePayload>;
  notificationDeleteAll?: Maybe<NotificationDeleteAllPayload>;
  notificationUpdate?: Maybe<NotificationUpdatePayload>;
  postAbuseReport?: Maybe<Scalars['Boolean']>;
  postCollectionCreate?: Maybe<PostCollectionCreatePayload>;
  postCollectionDelete?: Maybe<PostCollectionDeletePayload>;
  postCollectionRebuild?: Maybe<PostCollectionRebuildPayload>;
  postCollectionUpdate?: Maybe<PostCollectionUpdatePayload>;
  postCreate?: Maybe<PostCreatePayload>;
  postDelete?: Maybe<PostDeletePayload>;
  postDeleteMany?: Maybe<PostDeleteManyPayload>;
  postDislike?: Maybe<PostDislikePayload>;
  postLike?: Maybe<PostLikePayload>;
  postRate?: Maybe<PostRatePayload>;
  postRead?: Maybe<PostReadPayload>;
  postRemoveLike?: Maybe<PostRemoveLikePayload>;
  postsUpdate?: Maybe<PostsUpdatePayload>;
  postUpdate?: Maybe<PostUpdatePayload>;
  postUpdateMany?: Maybe<PostUpdateManyPayload>;
  /** 운영자가 댓글 전체 신고내역을 확인 */
  readAllCommentAbuseReportLog?: Maybe<CommentAbuseReportLogBulkUpdatePayload>;
  /** 운영자가 게시글 전체 신고내역을 확인 */
  readAllPostAbuseReportLog?: Maybe<PostAbuseReportLogBulkUpdatePayload>;
  /** 운영자가 댓글 신고내역을 확인 */
  readCommentAbuseReportLog?: Maybe<CommentAbuseReportLogUpdatePayload>;
  /** 운영자가 게시글 신고내역을 확인 */
  readPostAbuseReportLog?: Maybe<PostAbuseReportLogUpdatePayload>;
  removeBookmark?: Maybe<RemoveBookmarkPayload>;
  /** 댓글을 신고 */
  reportComment?: Maybe<ReportCommentPayload>;
  /** 게시물을 신고 */
  reportPost?: Maybe<ReportPostPayload>;
  requestCashout: RequestCashoutPayload;
  /**
   * 관리자만 이용 가능한 서비스 입니다.
   *
   * 멘토 게시글 복구
   */
  restoreMentorPost: RestoreMentorPostPayload;
  restorePost?: Maybe<RestorePostPayload>;
  /**
   * 관리자 전용
   *
   * 바로가기 메뉴를 생성한다.
   *
   * sitemapID만 주어질 경우 sitemap 아래에, parentID만 주어질 경우 menu 아래에 생성된다.
   *
   * 성공 시 생성된 메뉴를 반환한다.
   *
   * sitemapID와 parentID 둘 다 입력하지 않았을 시, 또는 둘 다 입력했을 시 Exception.
   *
   * 실패 시 Exception.
   */
  shortcutMenuCreate: ShortcutMenuCreatePayload;
  /**
   * 관리자 전용
   *
   * 바로가기 메뉴를 수정한다.
   *
   * 성공 시 수정된 메뉴를 반환한다.
   *
   * 해당 id를 가진 바로가기 메뉴가 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  shortcutMenuUpdate: ShortcutMenuUpdatePayload;
  /**
   * 관리자 전용
   *
   * 단순 메뉴를 생성한다.
   *
   * sitemapID만 주어질 경우 sitemap 아래에, parentID만 주어질 경우 menu 아래에 생성된다.
   *
   * 성공 시 생성된 메뉴를 반환한다.
   *
   * sitemapID와 parentID 둘 다 입력하지 않았을 시, 또는 둘 다 입력했을 시 Exception.
   *
   * 실패 시 Exception.
   */
  simpleMenuCreate: SimpleMenuCreatePayload;
  /**
   * 관리자 전용
   *
   * 단순 메뉴를 수정한다.
   *
   * 성공 시 수정된 메뉴를 반환한다.
   *
   * 해당 id를 가진 단순 메뉴가 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  simpleMenuUpdate: SimpleMenuUpdatePayload;
  /**
   * 사이트맵을 생성한다.
   *
   * 성공 시 생성된 사이트맵을 반환한다.
   *
   * 실패 시 Exception.
   */
  sitemapCreate: SitemapCreatePayload;
  /**
   * 사이트맵을 삭제한다.
   *
   * 성공 시 삭제된 사이트맵을 반환한다.
   *
   * 해당 id를 가진 사이트맵이 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  sitemapDelete: SitemapDeletePayload;
  /**
   * 해당 사이트맵을 1순위 먼저 나오게(direction = UP), 또는 1순위 나중에 나오게(direction = DOWN) 변경합니다.
   *
   * 변경한 사이트맵과 그 변경으로 인해 순위가 변경된 또 다른 한개의 사이트맵을 반환합니다.
   *
   * 해당 id를 가진 사이트맵이 없다면 payload 내부에 null을 반환합니다.
   *
   * 관리자 전용
   */
  sitemapMove: SitemapMovePayload;
  /**
   * 사이트맵을 수정한다.
   *
   * 성공 시 수정된 사이트맵을 반환한다.
   *
   * 해당 id를 가진 사이트맵이 없다면 null을 반환한다.
   *
   * 실패 시 Exception.
   */
  sitemapUpdate: SitemapUpdatePayload;
  tagCreate?: Maybe<TagCreatePayload>;
  tagDelete?: Maybe<TagDeletePayload>;
  tagDeleteMany?: Maybe<TagDeleteManyPayload>;
  tagUpdate?: Maybe<TagUpdatePayload>;
  topicCreate: TopicCreatePayload;
  topicDelete: TopicDeletePayload;
  topicUpdate: TopicUpdatePayload;
  trashCreate?: Maybe<TrashCreatePayload>;
  unbanUsers?: Maybe<UnbanLogsPayLoad>;
  unmuteUser?: Maybe<User>;
  updateBestOfBestRequirement?: Maybe<CollectionRequirementUpdatePayload>;
  updateBestPostRequirement?: Maybe<CollectionRequirementUpdatePayload>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateMentorBoards: UpdateMentorBoardsPayload;
  /** 멘토 게시글 수정시 내용 및 답변받고 싶은 현직자 정보를 수정 */
  updateMentorPost: UpdateMentorPostPayload;
  /**
   * 관리자만 이용 가능한 서비스 입니다.
   *
   * 멘토 게시글 여러개 게시판 수정 또는 삭제
   */
  updateMentorPosts: UpdateMentorPostsPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateMentorUserStatus: UpdateMentorUserStatusPayload;
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateOrganizationGroup: UpdateOrganizationGroupPayload;
  updatePostBanWords: Array<Scalars['String']>;
  /** 관리자만 이용 가능한 서비스 입니다. */
  updateRewardAmountSettings: UpdateRewardAmountSettingsPayload;
  updateUserProfileImage?: Maybe<User>;
  /** 멘토 게시판 게시글의 작성 한도를 검사합니다. */
  validateCreateMentorPostPolicy: Scalars['Boolean'];
};


export type MutationAcceptMentorCommentArgs = {
  id: Scalars['ID'];
};


export type MutationAddBookmarkArgs = {
  id?: InputMaybe<Scalars['ID']>;
  menuID?: InputMaybe<Scalars['ID']>;
};


export type MutationAttachmentCompleteUploadArgs = {
  attachmentID: Scalars['ID'];
};


export type MutationAttachmentDeleteArgs = {
  attachmentID: Scalars['ID'];
};


export type MutationAttachmentPrepareUploadArgs = {
  commentID?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  postID?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<Scalars['Int']>;
  type: Scalars['String'];
};


export type MutationAuthenticateEmailArgs = {
  email: Scalars['String'];
};


export type MutationAuthenticateWithDocumentsArgs = {
  input: AuthenticateWithDocumentsInput;
};


export type MutationAuthenticateWithEmailArgs = {
  input: AuthenticateWithEmailInput;
};


export type MutationBanUserArgs = {
  input: BanLogInput;
};


export type MutationBoardCreateArgs = {
  input: BoardCreateInput;
  sitemapID: Scalars['ID'];
};


export type MutationBoardDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationBoardMenuCreateArgs = {
  input: BoardMenuCreateInput;
};


export type MutationBoardMenuMergeArgs = {
  options?: InputMaybe<BoardMenuMergeOptionInput>;
  sourceMenuId: Scalars['ID'];
  targetMenuId: Scalars['ID'];
};


export type MutationBoardMenuUpdateArgs = {
  id: Scalars['ID'];
  input: BoardMenuUpdateInput;
};


export type MutationBoardMergeArgs = {
  options?: InputMaybe<BoardMenuMergeOptionInput>;
  sourceBoardId: Scalars['ID'];
  targetBoardId: Scalars['ID'];
};


export type MutationBoardNavigationUpdateArgs = {
  id: Scalars['ID'];
  input: BoardNavigationUpdateInput;
};


export type MutationBoardUpdateArgs = {
  input: BoardUpdateInput;
};


export type MutationCommentAbuseReportArgs = {
  id: Scalars['ID'];
};


export type MutationCommentCreateArgs = {
  input: CommentInput;
  parentID?: InputMaybe<Scalars['ID']>;
  postID: Scalars['ID'];
};


export type MutationCommentDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationCommentDislikeArgs = {
  id: Scalars['ID'];
};


export type MutationCommentLikeArgs = {
  id: Scalars['ID'];
};


export type MutationCommentRateArgs = {
  id: Scalars['ID'];
  rating: RatingType;
};


export type MutationCommentRemoveLikeArgs = {
  id: Scalars['ID'];
};


export type MutationCommentUpdateArgs = {
  id: Scalars['ID'];
  input: CommentInput;
};


export type MutationConfigResetArgs = {
  id: Scalars['ID'];
};


export type MutationConfigUpdateArgs = {
  id: Scalars['ID'];
  input: ConfigInput;
};


export type MutationCreateMentorBoardArgs = {
  id: Scalars['ID'];
  menteeAcceptableCommentCount?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateMentorCommentArgs = {
  input: CreateMentorCommentInput;
};


export type MutationCreateMentorPostArgs = {
  input: CreateMentorPostInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationCreateOrganizationGroupArgs = {
  input: CreateOrganizationGroupInput;
};


export type MutationCurrentUserScrapCreateArgs = {
  postID: Scalars['ID'];
};


export type MutationCurrentUserScrapDeleteArgs = {
  postID: Scalars['ID'];
};


export type MutationDeleteMentorBoardArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMentorCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMentorDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMentorPostArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationGroupArgs = {
  id: Scalars['ID'];
};


export type MutationDuplicateCheckOrganizationGroupNameArgs = {
  name: Scalars['String'];
};


export type MutationGroupCreateArgs = {
  input: GroupCreateInput;
};


export type MutationGroupDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationGroupUpdateArgs = {
  id: Scalars['ID'];
  input: GroupUpdateInput;
};


export type MutationHidePostFromOthersArgs = {
  expiresAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
};


export type MutationIssueSignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>;
};


export type MutationMainMenuSetArgs = {
  id: Scalars['ID'];
};


export type MutationMentorDocumentCompleteUploadArgs = {
  id: Scalars['ID'];
};


export type MutationMentorDocumentPrepareUploadArgs = {
  input: MentorDocumentPrepareUploadInput;
};


export type MutationMenuAndBoardDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMenuDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMenuDuplicateArgs = {
  id: Scalars['ID'];
  parentID?: InputMaybe<Scalars['ID']>;
  sitemapID?: InputMaybe<Scalars['ID']>;
};


export type MutationMenuMoveArgs = {
  direction: MenuMoveDirection;
  id: Scalars['ID'];
};


export type MutationMessageCreateArgs = {
  input: MessageCreateInput;
};


export type MutationMessageDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationMessageDeleteAllArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationMessageStoreArgs = {
  id: Scalars['ID'];
};


export type MutationMessageUpdateArgs = {
  id: Scalars['ID'];
};


export type MutationMuteUserArgs = {
  mutedUserID: Scalars['ID'];
};


export type MutationNotificationCreateArgs = {
  input: NotificationCreateInput;
};


export type MutationNotificationDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationNotificationUpdateArgs = {
  hasRead: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationPostAbuseReportArgs = {
  id: Scalars['ID'];
};


export type MutationPostCollectionCreateArgs = {
  input: PostCollectionInput;
};


export type MutationPostCollectionDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPostCollectionRebuildArgs = {
  automation?: InputMaybe<PostCollectionAutomationInput>;
  id: Scalars['ID'];
};


export type MutationPostCollectionUpdateArgs = {
  id: Scalars['ID'];
  input: PostCollectionInput;
};


export type MutationPostCreateArgs = {
  input: PostInput;
};


export type MutationPostDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationPostDeleteManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationPostDislikeArgs = {
  id: Scalars['ID'];
};


export type MutationPostLikeArgs = {
  id: Scalars['ID'];
};


export type MutationPostRateArgs = {
  id: Scalars['ID'];
  rating: RatingType;
};


export type MutationPostReadArgs = {
  id: Scalars['ID'];
};


export type MutationPostRemoveLikeArgs = {
  id: Scalars['ID'];
};


export type MutationPostsUpdateArgs = {
  boardID?: InputMaybe<Scalars['ID']>;
  input: PostsUpdateInput;
};


export type MutationPostUpdateArgs = {
  id: Scalars['ID'];
  input: PostUpdateInput;
};


export type MutationPostUpdateManyArgs = {
  ids: Array<Scalars['ID']>;
  input: PostUpdateManyInput;
};


export type MutationReadAllCommentAbuseReportLogArgs = {
  commentID: Scalars['ID'];
};


export type MutationReadAllPostAbuseReportLogArgs = {
  postID: Scalars['ID'];
};


export type MutationReadCommentAbuseReportLogArgs = {
  id: Scalars['ID'];
};


export type MutationReadPostAbuseReportLogArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveBookmarkArgs = {
  id?: InputMaybe<Scalars['ID']>;
  menuID?: InputMaybe<Scalars['ID']>;
};


export type MutationReportCommentArgs = {
  input: ReportCommentInput;
};


export type MutationReportPostArgs = {
  input: ReportPostInput;
};


export type MutationRequestCashoutArgs = {
  input: RequestCashoutInput;
};


export type MutationRestoreMentorPostArgs = {
  id: Scalars['ID'];
};


export type MutationRestorePostArgs = {
  id: Scalars['ID'];
};


export type MutationShortcutMenuCreateArgs = {
  input: ShortcutMenuCreateInput;
};


export type MutationShortcutMenuUpdateArgs = {
  id: Scalars['ID'];
  input: ShortcutMenuUpdateInput;
};


export type MutationSimpleMenuCreateArgs = {
  input: SimpleMenuCreateInput;
};


export type MutationSimpleMenuUpdateArgs = {
  id: Scalars['ID'];
  input: SimpleMenuUpdateInput;
};


export type MutationSitemapCreateArgs = {
  input: SitemapCreateInput;
};


export type MutationSitemapDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationSitemapMoveArgs = {
  direction: SitemapMoveDirection;
  id: Scalars['ID'];
};


export type MutationSitemapUpdateArgs = {
  id: Scalars['ID'];
  input: SitemapUpdateInput;
};


export type MutationTagCreateArgs = {
  input: TagInput;
};


export type MutationTagDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTagDeleteManyArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationTagUpdateArgs = {
  id: Scalars['ID'];
  input: TagInput;
};


export type MutationTopicCreateArgs = {
  input: TopicCreateInput;
};


export type MutationTopicDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationTopicUpdateArgs = {
  id: Scalars['ID'];
  input: TopicUpdateInput;
};


export type MutationTrashCreateArgs = {
  input?: InputMaybe<TrashInput>;
};


export type MutationUnbanUsersArgs = {
  logIDs: Array<Scalars['ID']>;
};


export type MutationUnmuteUserArgs = {
  mutedUserID: Scalars['ID'];
};


export type MutationUpdateBestOfBestRequirementArgs = {
  input: CollectionRequirementUpdateInput;
};


export type MutationUpdateBestPostRequirementArgs = {
  input: CollectionRequirementUpdateInput;
};


export type MutationUpdateMentorBoardsArgs = {
  input: UpdateMentorBoardsInput;
};


export type MutationUpdateMentorPostArgs = {
  input: UpdateMentorPostInput;
};


export type MutationUpdateMentorPostsArgs = {
  input: UpdateMentorPostsInput;
};


export type MutationUpdateMentorUserStatusArgs = {
  input: UpdateMentorUserStatusInput;
};


export type MutationUpdateOrganizationGroupArgs = {
  input: UpdateOrganizationGroupInput;
};


export type MutationUpdatePostBanWordsArgs = {
  banWords: Array<Scalars['String']>;
};


export type MutationUpdateRewardAmountSettingsArgs = {
  input: UpdateRewardAmountSettingsInput;
};


export type MutationUpdateUserProfileImageArgs = {
  input: UpdateUserInput;
};

/** 멘토 댓글을 채택할 때 반환하는 객체 */
export type AcceptMentorCommentPayload = {
  __typename?: 'AcceptMentorCommentPayload';
  comment: Comment;
};

export type AddBookmarkPayload = {
  __typename?: 'AddBookmarkPayload';
  bookmarkable?: Maybe<Bookmarkable>;
  /** @deprecated Use `bookmarkable`. */
  menu?: Maybe<Menu>;
};

export type AttachmentCompleteUploadPayload = {
  __typename?: 'AttachmentCompleteUploadPayload';
  attachment?: Maybe<Attachment>;
};

export type AttachmentDeletePayload = {
  __typename?: 'AttachmentDeletePayload';
  attachment?: Maybe<Attachment>;
};

export type AttachmentPrepareUploadPayload = {
  __typename?: 'AttachmentPrepareUploadPayload';
  attachment: Attachment;
  signedURL: Scalars['String'];
};

/** 이메일 인증 번호 발송 후 반환하는 객체 */
export type AuthenticateEmailPayload = {
  __typename?: 'AuthenticateEmailPayload';
  /** 이메일 인증 코드 조회를 위한 인증 토큰 */
  authenticationToken: Scalars['String'];
};

export type AuthenticateWithDocumentsInput = {
  /** 멘토 직무 아이디 */
  dutyId?: InputMaybe<Scalars['ID']>;
  /** 멘토 직무 이름 */
  dutyName?: InputMaybe<Scalars['String']>;
  /** 멘토 인증 서류 아이디 리스트 */
  mentorDocumentIds: Array<Scalars['ID']>;
  /** 멘토 기업 이름 */
  organizationName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  /** 멘토 최종 학력 이름 */
  schoolName: Scalars['String'];
};

export type AuthenticateWithDocumentsPayloadDto = {
  __typename?: 'AuthenticateWithDocumentsPayloadDto';
  user: User;
};

export type AuthenticateWithEmailInput = {
  authenticationToken: Scalars['String'];
  dutyId?: InputMaybe<Scalars['ID']>;
  dutyName?: InputMaybe<Scalars['String']>;
  emailCode: Scalars['String'];
  organizationName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  schoolName: Scalars['String'];
};

/** 이메일 검증 후 생성된 유저를 반환하는 객체 */
export type AuthenticateWithEmailPayload = {
  __typename?: 'AuthenticateWithEmailPayload';
  /** 이메일 검증 후 생성된 유저를 반환 */
  user: User;
};

export type BanLogInput = {
  description?: InputMaybe<Scalars['String']>;
  endAt?: InputMaybe<Scalars['Date']>;
  startAt?: InputMaybe<Scalars['Date']>;
  userID: Scalars['ID'];
};

export type BoardCreateInput = {
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  footerText?: InputMaybe<Scalars['String']>;
  headerText?: InputMaybe<Scalars['String']>;
  isMentorBoard?: InputMaybe<Scalars['Boolean']>;
  mobileContent?: InputMaybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

export type BoardCreatePayload = {
  __typename?: 'BoardCreatePayload';
  board: Board;
};

export type BoardDeletePayload = {
  __typename?: 'BoardDeletePayload';
  board: Board;
};

/**
 * 관리자 전용
 *
 * 게시판 메뉴를 생성할 때 보내야 하는 객체
 */
export type BoardMenuCreateInput = {
  boardSlug: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

/**
 * 관리자 전용
 *
 * 게시판 메뉴를 생성할 때 반환하는 객체
 */
export type BoardMenuCreatePayload = {
  __typename?: 'BoardMenuCreatePayload';
  menu: BoardMenu;
};

/**
 * 관리자 전용
 *
 * 게시판을 가리키는 메뉴
 */
export type BoardMenu = IMenu & {
  __typename?: 'BoardMenu';
  board?: Maybe<Board>;
  /** 현재 매뉴의 자식 메뉴 리스트 */
  children: Array<IMenu>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOrder: Scalars['String'];
  /**
   * 현재 메뉴의 부모 메뉴
   *
   * 사이트맵의 자식인 경우 null
   */
  parent?: Maybe<IMenu>;
  /**
   * 모든 사용자: 비어있는 리스트
   *
   * 관리자는 단독으로만 존재 가능
   *
   * 선택 그룹 사용자: [그룹1, 그룹2, ...]
   */
  permissionViewMenuGroups: Array<Group>;
  /** 현재 메뉴가 속한 사이트맵 */
  sitemap?: Maybe<Sitemap>;
  title?: Maybe<Scalars['String']>;
};

export type BoardMenuMergeOptionInput = {
  topicID?: InputMaybe<Scalars['ID']>;
};

/**
 * 관리자 전용
 *
 * 게시판 메뉴를 병합할 때 빈환하는 객체
 */
export type BoardMenuMergePayload = {
  __typename?: 'BoardMenuMergePayload';
  menu: BoardMenu;
};

/**
 * 관리자 전용
 *
 * 게시판 메뉴를 수정할 때 보내야 하는 객체
 */
export type BoardMenuUpdateInput = {
  boardSlug?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  permissionViewMenuGroupIDs?: InputMaybe<Array<Scalars['ID']>>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

/**
 * 관리자 전용
 *
 * 게시판 메뉴를 수정할 때 반환하는 객체
 */
export type BoardMenuUpdatePayload = {
  __typename?: 'BoardMenuUpdatePayload';
  menu?: Maybe<BoardMenu>;
};

export type BoardMergePayload = {
  __typename?: 'BoardMergePayload';
  board: Board;
};

export type BoardNavigationUpdateInput = {
  board: BoardUpdateInput;
};

export type BoardUpdateInput = {
  anonymity?: InputMaybe<BoardAnonymity>;
  content?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  footerText?: InputMaybe<Scalars['String']>;
  headerText?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  mobileContent?: InputMaybe<Scalars['String']>;
  noticeHidden?: InputMaybe<Scalars['Boolean']>;
  orderDirection?: InputMaybe<BoardPostOrderDirection>;
  orderField?: InputMaybe<BoardPostOrderField>;
  permissions?: InputMaybe<Array<PermissionInput>>;
  secretPostWritable?: InputMaybe<Scalars['Boolean']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  useTopic?: InputMaybe<Scalars['Boolean']>;
};

export type PermissionInput = {
  groupIDs: Array<Scalars['ID']>;
  type: BoardPermissionType;
};

export type BoardNavigationUpdatePayload = {
  __typename?: 'BoardNavigationUpdatePayload';
  boardNavigation?: Maybe<BoardNavigation>;
};

export type BoardUpdatePayload = {
  __typename?: 'BoardUpdatePayload';
  board: Board;
};

export type CommentInput = {
  attachmentIDs?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['String'];
  contentText?: InputMaybe<Scalars['String']>;
  isMentor?: InputMaybe<Scalars['Boolean']>;
  isSecret?: InputMaybe<Scalars['Boolean']>;
  mentionedCommentID?: InputMaybe<Scalars['ID']>;
};

export type CommentCreatePayload = {
  __typename?: 'CommentCreatePayload';
  comment?: Maybe<Comment>;
  parentComment?: Maybe<Comment>;
  post?: Maybe<Post>;
};

export type CommentDeletePayload = {
  __typename?: 'CommentDeletePayload';
  deletedCommentID?: Maybe<Scalars['String']>;
  parentComment?: Maybe<Comment>;
  post?: Maybe<Post>;
};

export type CommentDislikePayload = {
  __typename?: 'CommentDislikePayload';
  comment?: Maybe<Comment>;
};

export type CommentLikePayload = {
  __typename?: 'CommentLikePayload';
  comment?: Maybe<Comment>;
};

export type CommentRatePayload = {
  __typename?: 'CommentRatePayload';
  comment?: Maybe<Comment>;
};

export type CommentRemoveLikePayload = {
  __typename?: 'CommentRemoveLikePayload';
  comment?: Maybe<Comment>;
};

export type CommentUpdatePayload = {
  __typename?: 'CommentUpdatePayload';
  comment?: Maybe<Comment>;
};

export type ConfigInput = {
  value?: InputMaybe<Scalars['String']>;
};

export type CreateMentorBoardPayload = {
  __typename?: 'CreateMentorBoardPayload';
  board: Board;
};

export type CreateMentorCommentInput = {
  attachmentIDs?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['String'];
  contentText?: InputMaybe<Scalars['String']>;
  isSecret?: InputMaybe<Scalars['Boolean']>;
  mentionedCommentID?: InputMaybe<Scalars['ID']>;
  parentID?: InputMaybe<Scalars['ID']>;
  postId: Scalars['ID'];
};

/** 멘토 댓글 생성할 때 반환하는 객체 */
export type CreateMentorCommentPayload = {
  __typename?: 'CreateMentorCommentPayload';
  comment: Comment;
};

/** 멘토 게시글 생성할 때 보내야 하는 객체 */
export type CreateMentorPostInput = {
  allowTrackback?: InputMaybe<Scalars['Boolean']>;
  attachmentIDs?: InputMaybe<Array<Scalars['String']>>;
  boardId: Scalars['ID'];
  commentStatus?: InputMaybe<PostCommentStatus>;
  containsImage?: InputMaybe<Scalars['Boolean']>;
  containsYoutube?: InputMaybe<Scalars['Boolean']>;
  content: Scalars['String'];
  contentText: Scalars['String'];
  /** use dutyIds */
  dutyId?: InputMaybe<Scalars['ID']>;
  dutyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** use dutyIds */
  dutyName?: InputMaybe<Scalars['String']>;
  imageSrc?: InputMaybe<Scalars['String']>;
  isNotice?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['Boolean']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']>>;
  /** use organizationIds */
  organizationName?: InputMaybe<Scalars['String']>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  /** use schoolIds */
  schoolName?: InputMaybe<Scalars['String']>;
  status: PostStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  youtubeSrc?: InputMaybe<Scalars['String']>;
};

/** 멘토 게시글 생성할 때 반환하는 객체 */
export type CreateMentorPostPayload = {
  __typename?: 'CreateMentorPostPayload';
  post: Post;
};

export type CreateOrganizationInput = {
  /** 생성할 기업명 */
  name: Scalars['String'];
};

export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  organization: Organization;
};

/** 기업 그룹 생성할 때 보내야 하는 객체 */
export type CreateOrganizationGroupInput = {
  name: Scalars['String'];
};

/** 기업 그룹 생성할 때 반환되는 객체 */
export type CreateOrganizationGroupPayload = {
  __typename?: 'CreateOrganizationGroupPayload';
  organizationGroup: OrganizationGroup;
};

export type CurrentUserScrapCreatePayload = {
  __typename?: 'CurrentUserScrapCreatePayload';
  userScrap?: Maybe<UserScrap>;
};

export type CurrentUserScrapDeletePayload = {
  __typename?: 'CurrentUserScrapDeletePayload';
  userScrap?: Maybe<UserScrap>;
};

export type CurrentUserScrapsDeleteAllPayload = {
  __typename?: 'CurrentUserScrapsDeleteAllPayload';
  userScrap: Array<UserScrap>;
};

export type DeleteMentorBoardPayload = {
  __typename?: 'DeleteMentorBoardPayload';
  board?: Maybe<Board>;
};

/** 멘토 댓글 삭제할 때 반환하는 객체 */
export type DeleteMentorCommentPayload = {
  __typename?: 'DeleteMentorCommentPayload';
  comment: Comment;
};

export type DeleteMentorDocumentPayload = {
  __typename?: 'DeleteMentorDocumentPayload';
  mentorDocument: MentorDocument;
};

/** 게시글 작성자가 멘토 게시글 삭제할 때 반환하는 객체 */
export type DeleteMentorPostPayload = {
  __typename?: 'DeleteMentorPostPayload';
  post: Post;
};

/** 기업 그룹 삭제할 때 반환되는 객체 */
export type DeleteOrganizationGroupPayload = {
  __typename?: 'DeleteOrganizationGroupPayload';
  organizationGroup: OrganizationGroup;
};

/** 그룹을 생성할 때 보내야 하는 객체 */
export type GroupCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** 그룹을 생성할 때 반환하는 객체 */
export type GroupCreatePayload = {
  __typename?: 'GroupCreatePayload';
  group: Group;
};

/** 그룹을 삭제할 때 반환하는 객체 */
export type GroupDeletePayload = {
  __typename?: 'GroupDeletePayload';
  group?: Maybe<Group>;
};

/** 그룹을 수정할 때 보내야 하는 객체 */
export type GroupUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/** 그룹을 수정할 때 반환하는 객체 */
export type GroupUpdatePayload = {
  __typename?: 'GroupUpdatePayload';
  group?: Maybe<Group>;
};

export type HidePostPayload = {
  __typename?: 'HidePostPayload';
  post?: Maybe<Post>;
};

export type MentorDocumentCompleteUploadPayload = {
  __typename?: 'MentorDocumentCompleteUploadPayload';
  mentorDocument: MentorDocument;
};

export type MentorDocumentPrepareUploadInput = {
  name: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type MentorDocumentPrepareUploadPayload = {
  __typename?: 'MentorDocumentPrepareUploadPayload';
  mentorDocument: MentorDocument;
  signedUrl: Scalars['String'];
};

/**
 * 관리자 전용
 *
 * 게시판 매뉴를 삭제할 때 반환하는 객체
 */
export type MenuDeletePayload = {
  __typename?: 'MenuDeletePayload';
  menu?: Maybe<IMenu>;
};

export type MenuDuplicatePayload = {
  __typename?: 'MenuDuplicatePayload';
  menu: IMenu;
};

export enum MenuMoveDirection {
  DOWN = 'DOWN',
  UP = 'UP'
}

export type MenuMovePayload = {
  __typename?: 'MenuMovePayload';
  /** 순서를 변경한 메뉴 */
  menu?: Maybe<IMenu>;
  /** 변경으로 인해 순서가 변경된 나머지 메뉴 */
  swappedMenu?: Maybe<IMenu>;
};

export type MessageCreateInput = {
  content: Scalars['String'];
  isReplied: Scalars['Boolean'];
  receiverID: Scalars['Int'];
};

export type MessageCreatePayload = {
  __typename?: 'MessageCreatePayload';
  message?: Maybe<Message>;
};

export type MessageDeletePayload = {
  __typename?: 'MessageDeletePayload';
  message?: Maybe<Message>;
};

export type MessageDeleteAllPayload = {
  __typename?: 'MessageDeleteAllPayload';
  count?: Maybe<Scalars['Int']>;
};

export type MessageStorePayload = {
  __typename?: 'MessageStorePayload';
  message?: Maybe<Message>;
};

export type MessageUpdatePayload = {
  __typename?: 'MessageUpdatePayload';
  message?: Maybe<Message>;
};

export type NotificationCreateInput = {
  content: Scalars['String'];
  linkURL: Scalars['String'];
  notificationType: Scalars['String'];
  receiverID: Scalars['Int'];
};

export type NotificationCreatePayload = {
  __typename?: 'NotificationCreatePayload';
  notification?: Maybe<Message>;
};

export type NotificationDeletePayload = {
  __typename?: 'NotificationDeletePayload';
  notification?: Maybe<Message>;
};

export type NotificationDeleteAllPayload = {
  __typename?: 'NotificationDeleteAllPayload';
  count?: Maybe<Scalars['Int']>;
};

export type NotificationUpdatePayload = {
  __typename?: 'NotificationUpdatePayload';
  notification?: Maybe<Message>;
};

export type PostCollectionInput = {
  automation?: InputMaybe<PostCollectionAutomationInput>;
  postIDs?: InputMaybe<Array<Scalars['ID']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type PostCollectionAutomationInput = {
  boardIDs?: InputMaybe<Array<Scalars['ID']>>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<PostFiltersInput>;
};

export type PostCollectionCreatePayload = {
  __typename?: 'PostCollectionCreatePayload';
  postCollection?: Maybe<PostCollection>;
};

export type PostCollectionDeletePayload = {
  __typename?: 'PostCollectionDeletePayload';
  postCollection?: Maybe<PostCollection>;
};

export type PostCollectionRebuildPayload = {
  __typename?: 'PostCollectionRebuildPayload';
  postCollection?: Maybe<PostCollection>;
};

export type PostCollectionUpdatePayload = {
  __typename?: 'PostCollectionUpdatePayload';
  postCollection?: Maybe<PostCollection>;
};

export type PostInput = {
  allowTrackback?: InputMaybe<Scalars['Boolean']>;
  attachmentIDs?: InputMaybe<Array<Scalars['ID']>>;
  boardID: Scalars['Int'];
  commentStatus?: InputMaybe<PostCommentStatus>;
  containsImage?: InputMaybe<Scalars['Boolean']>;
  containsYoutube?: InputMaybe<Scalars['Boolean']>;
  content: Scalars['String'];
  contentText: Scalars['String'];
  embeddables?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageSrc?: InputMaybe<Scalars['String']>;
  isNotice?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['Boolean']>;
  status: PostStatus;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  topicID?: InputMaybe<Scalars['ID']>;
  youtubeSrc?: InputMaybe<Scalars['String']>;
};

export type PostCreatePayload = {
  __typename?: 'PostCreatePayload';
  menu?: Maybe<Menu>;
  post?: Maybe<Post>;
};

export type PostDeletePayload = {
  __typename?: 'PostDeletePayload';
  deletedPostID?: Maybe<Scalars['ID']>;
  post?: Maybe<Post>;
};

export type PostDeleteManyPayload = {
  __typename?: 'PostDeleteManyPayload';
  posts: Array<Post>;
};

export type PostDislikePayload = {
  __typename?: 'PostDislikePayload';
  post?: Maybe<Post>;
};

export type PostLikePayload = {
  __typename?: 'PostLikePayload';
  post?: Maybe<Post>;
};

export type PostRatePayload = {
  __typename?: 'PostRatePayload';
  post?: Maybe<Post>;
};

export type PostReadPayload = {
  __typename?: 'PostReadPayload';
  post?: Maybe<Post>;
};

export type PostRemoveLikePayload = {
  __typename?: 'PostRemoveLikePayload';
  post?: Maybe<Post>;
};

export type PostsUpdateInput = {
  isDelete: Scalars['Boolean'];
  postIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type PostsUpdatePayload = {
  __typename?: 'PostsUpdatePayload';
  posts?: Maybe<Array<Maybe<Post>>>;
};

export type PostUpdateInput = {
  allowTrackback?: InputMaybe<Scalars['Boolean']>;
  attachmentIDs?: InputMaybe<Array<Scalars['ID']>>;
  boardID?: InputMaybe<Scalars['Int']>;
  commentStatus?: InputMaybe<PostCommentStatus>;
  containsImage?: InputMaybe<Scalars['Boolean']>;
  containsYoutube?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Scalars['String']>;
  contentText?: InputMaybe<Scalars['String']>;
  embeddables?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  globalNotice?: InputMaybe<Scalars['Boolean']>;
  imageSrc?: InputMaybe<Scalars['String']>;
  isNotice?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['Boolean']>;
  postCollectionIDs?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<PostStatus>;
  tagIDs?: InputMaybe<Array<Scalars['ID']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  topicID?: InputMaybe<Scalars['ID']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  youtubeSrc?: InputMaybe<Scalars['String']>;
};

export type PostUpdatePayload = {
  __typename?: 'PostUpdatePayload';
  post?: Maybe<Post>;
};

export type PostUpdateManyInput = {
  globalNotice?: InputMaybe<Scalars['Boolean']>;
};

export type PostUpdateManyPayload = {
  __typename?: 'PostUpdateManyPayload';
  posts: Array<Post>;
};

export type CommentAbuseReportLogBulkUpdatePayload = {
  __typename?: 'CommentAbuseReportLogBulkUpdatePayload';
  updatedCommentAbuseReportLogs: Scalars['Int'];
};

export type PostAbuseReportLogBulkUpdatePayload = {
  __typename?: 'PostAbuseReportLogBulkUpdatePayload';
  updatedPostAbuseReportLogs: Scalars['Int'];
};

export type CommentAbuseReportLogUpdatePayload = {
  __typename?: 'CommentAbuseReportLogUpdatePayload';
  commentAbuseReportLog?: Maybe<CommentAbuseReportLog>;
};

export type PostAbuseReportLogUpdatePayload = {
  __typename?: 'PostAbuseReportLogUpdatePayload';
  postAbuseReportLog?: Maybe<PostAbuseReportLog>;
};

export type RemoveBookmarkPayload = {
  __typename?: 'RemoveBookmarkPayload';
  bookmarkable?: Maybe<Bookmarkable>;
  /** @deprecated Use `bookmarkable`. */
  menu?: Maybe<Menu>;
};

export type ReportCommentInput = {
  commentID: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
};

export type ReportCommentPayload = {
  __typename?: 'ReportCommentPayload';
  comment?: Maybe<Comment>;
};

export type ReportPostInput = {
  description?: InputMaybe<Scalars['String']>;
  postID: Scalars['ID'];
};

export type ReportPostPayload = {
  __typename?: 'ReportPostPayload';
  post?: Maybe<Post>;
};

export type RequestCashoutInput = {
  /** 현금 지급 신청 금액 */
  amount: Scalars['Int'];
  /** 입금 은행 */
  bank: Bank;
  /** 입금 은행 계좌번호 */
  bankAccountNumber: Scalars['String'];
  /** 유저 전화번호 */
  phoneNumber: Scalars['String'];
  /** 유저 실명 */
  realName: Scalars['String'];
};

export type RequestCashoutPayload = {
  __typename?: 'RequestCashoutPayload';
  requestCashoutLog: RequestCashoutLog;
};

/** 멘토 게시글 복구할 때 반환하는 객체 */
export type RestoreMentorPostPayload = {
  __typename?: 'RestoreMentorPostPayload';
  post: Post;
};

export type RestorePostPayload = {
  __typename?: 'RestorePostPayload';
  post?: Maybe<Post>;
};

/**
 * 관리자 전용
 *
 * 바로가기 메뉴를 생성할 때 보내야 하는 객체
 */
export type ShortcutMenuCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  slug: Scalars['String'];
  title: Scalars['String'];
};

/**
 * 관리자 전용
 *
 * 바로가기 메뉴를 생성할 때 반환하는 객체
 */
export type ShortcutMenuCreatePayload = {
  __typename?: 'ShortcutMenuCreatePayload';
  menu: ShortcutMenu;
};

/**
 * 관리자 전용
 *
 * 바로가기 또는 다른 메뉴를 가리키는 메뉴
 */
export type ShortcutMenu = IMenu & {
  __typename?: 'ShortcutMenu';
  /** 현재 매뉴의 자식 메뉴 리스트 */
  children: Array<IMenu>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOrder: Scalars['String'];
  /**
   * 현재 메뉴의 부모 메뉴
   *
   * 사이트맵의 자식인 경우 null
   */
  parent?: Maybe<IMenu>;
  /**
   * 모든 사용자: 비어있는 리스트
   *
   * 관리자는 단독으로만 존재 가능
   *
   * 선택 그룹 사용자: [그룹1, 그룹2, ...]
   */
  permissionViewMenuGroups: Array<Group>;
  /** 현재 메뉴가 속한 사이트맵 */
  sitemap?: Maybe<Sitemap>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/**
 * 관리자 전용
 *
 * 바로기가 메뉴를 수정할 때 보내야 하는 객체
 */
export type ShortcutMenuUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  permissionViewMenuGroupIDs?: InputMaybe<Array<Scalars['ID']>>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

/**
 * 관리자 전용
 *
 * 바로가기 메뉴를 수정할 때 반환하는 객체
 */
export type ShortcutMenuUpdatePayload = {
  __typename?: 'ShortcutMenuUpdatePayload';
  menu?: Maybe<ShortcutMenu>;
};

export type SimpleMenuCreateInput = {
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type SimpleMenuCreatePayload = {
  __typename?: 'SimpleMenuCreatePayload';
  menu: SimpleMenu;
};

/**
 * 관리자 전용
 *
 * 단순 구조용 메뉴
 */
export type SimpleMenu = IMenu & {
  __typename?: 'SimpleMenu';
  /** 현재 매뉴의 자식 메뉴 리스트 */
  children: Array<IMenu>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  listOrder: Scalars['String'];
  /**
   * 현재 메뉴의 부모 메뉴
   *
   * 사이트맵의 자식인 경우 null
   */
  parent?: Maybe<IMenu>;
  /**
   * 모든 사용자: 비어있는 리스트
   *
   * 관리자는 단독으로만 존재 가능
   *
   * 선택 그룹 사용자: [그룹1, 그룹2, ...]
   */
  permissionViewMenuGroups: Array<Group>;
  /** 현재 메뉴가 속한 사이트맵 */
  sitemap?: Maybe<Sitemap>;
  title?: Maybe<Scalars['String']>;
};

export type SimpleMenuUpdateInput = {
  description?: InputMaybe<Scalars['String']>;
  parentID?: InputMaybe<Scalars['ID']>;
  permissionViewMenuGroupIDs?: InputMaybe<Array<Scalars['ID']>>;
  sitemapID?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type SimpleMenuUpdatePayload = {
  __typename?: 'SimpleMenuUpdatePayload';
  menu?: Maybe<SimpleMenu>;
};

/** 사이트맵을 생성할 때 보내야 하는 객체 */
export type SitemapCreateInput = {
  title: Scalars['String'];
};

/** 사이트맵을 생성할 때 반환하는 객체 */
export type SitemapCreatePayload = {
  __typename?: 'SitemapCreatePayload';
  sitemap: Sitemap;
};

/** 사이트맵을 삭제할 때 반환하는 객체 */
export type SitemapDeletePayload = {
  __typename?: 'SitemapDeletePayload';
  sitemap?: Maybe<Sitemap>;
};

export enum SitemapMoveDirection {
  DOWN = 'DOWN',
  UP = 'UP'
}

export type SitemapMovePayload = {
  __typename?: 'SitemapMovePayload';
  /** 순서를 변경한 사이트맵 */
  sitemap?: Maybe<Sitemap>;
  /** 변경으로 인해 순서가 변경된 나머지 사이트맵 */
  swappedSitemap?: Maybe<Sitemap>;
};

/** 사이트맵을 수정할 때 보내야 하는 객체 */
export type SitemapUpdateInput = {
  title?: InputMaybe<Scalars['String']>;
};

/** 사이트맵을 수정할 때 반환하는 객체 */
export type SitemapUpdatePayload = {
  __typename?: 'SitemapUpdatePayload';
  sitemap?: Maybe<Sitemap>;
};

export type TagInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type TagCreatePayload = {
  __typename?: 'TagCreatePayload';
  tag?: Maybe<Tag>;
};

export type TagDeletePayload = {
  __typename?: 'TagDeletePayload';
  tag?: Maybe<Tag>;
};

export type TagDeleteManyPayload = {
  __typename?: 'TagDeleteManyPayload';
  tags: Array<Tag>;
};

export type TagUpdatePayload = {
  __typename?: 'TagUpdatePayload';
  tag?: Maybe<Tag>;
};

export type TopicCreateInput = {
  boardID: Scalars['ID'];
  name: Scalars['String'];
  rank: Scalars['String'];
};

export type TopicCreatePayload = {
  __typename?: 'TopicCreatePayload';
  topic: Topic;
};

export type TopicDeletePayload = {
  __typename?: 'TopicDeletePayload';
  topic: Topic;
};

export type TopicUpdateInput = {
  name?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
};

export type TopicUpdatePayload = {
  __typename?: 'TopicUpdatePayload';
  topic: Topic;
};

export type TrashInput = {
  createdAt: Scalars['Date'];
  description?: InputMaybe<Scalars['String']>;
  ipAddrress?: InputMaybe<Scalars['String']>;
  originModule?: InputMaybe<Scalars['String']>;
  serializedObject?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userID?: InputMaybe<Scalars['Int']>;
};

export type TrashCreatePayload = {
  __typename?: 'TrashCreatePayload';
  trash?: Maybe<Trash>;
};

export type UnbanLogsPayLoad = {
  __typename?: 'UnbanLogsPayLoad';
  unbanLogs?: Maybe<Array<Maybe<BanLog>>>;
};

export type CollectionRequirementUpdateInput = {
  comment?: InputMaybe<Scalars['Int']>;
  like?: InputMaybe<Scalars['Int']>;
  scrap?: InputMaybe<Scalars['Int']>;
  view?: InputMaybe<Scalars['Int']>;
};

export type CollectionRequirementUpdatePayload = {
  __typename?: 'CollectionRequirementUpdatePayload';
  collectionRequirement?: Maybe<CollectionRequirement>;
};

export type UpdateMentorBoardsInput = {
  updateMentorBoardInputs: Array<UpdateMentorBoardInput>;
};

export type UpdateMentorBoardInput = {
  id: Scalars['ID'];
  /** 채택 가능한 댓글 수 */
  menteeAcceptableCommentCount?: InputMaybe<Scalars['Int']>;
};

export type UpdateMentorBoardsPayload = {
  __typename?: 'UpdateMentorBoardsPayload';
  boards: Array<Board>;
};

/** 멘토 게시글 수정할 때 보내야 하는 객체 */
export type UpdateMentorPostInput = {
  allowTrackback?: InputMaybe<Scalars['Boolean']>;
  attachmentIDs?: InputMaybe<Array<Scalars['String']>>;
  boardId?: InputMaybe<Scalars['ID']>;
  commentStatus?: InputMaybe<PostCommentStatus>;
  containsImage?: InputMaybe<Scalars['Boolean']>;
  containsYoutube?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Scalars['String']>;
  contentText?: InputMaybe<Scalars['String']>;
  dutyId?: InputMaybe<Scalars['ID']>;
  dutyIds?: InputMaybe<Array<Scalars['ID']>>;
  /** 앱 구버전을 위해 남은 필드입니다. dutyId를 사용하세요. */
  dutyName?: InputMaybe<Scalars['String']>;
  globalNotice?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  imageSrc?: InputMaybe<Scalars['String']>;
  isNotice?: InputMaybe<Scalars['Boolean']>;
  notifyMessage?: InputMaybe<Scalars['Boolean']>;
  organizationIds?: InputMaybe<Array<Scalars['ID']>>;
  organizationName?: InputMaybe<Scalars['String']>;
  postCollectionIDs?: InputMaybe<Array<Scalars['String']>>;
  schoolIds?: InputMaybe<Array<Scalars['ID']>>;
  schoolName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PostStatus>;
  tagIDs?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  youtubeSrc?: InputMaybe<Scalars['String']>;
};

/** 멘토 게시글 수정할 때 반환하는 객체 */
export type UpdateMentorPostPayload = {
  __typename?: 'UpdateMentorPostPayload';
  post: Post;
};

/** 멘토 게시글 여러개의 게시판 수정 또는 삭제할 때 보내야 하는 객체 */
export type UpdateMentorPostsInput = {
  boardId?: InputMaybe<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  isDelete: Scalars['Boolean'];
};

/** 멘토 게시글 수정할 때 반환하는 객체 */
export type UpdateMentorPostsPayload = {
  __typename?: 'UpdateMentorPostsPayload';
  posts: Array<Post>;
};

export type UpdateMentorUserStatusInput = {
  /** 멘토 직무 아이디 */
  dutyIdInReview?: InputMaybe<Scalars['ID']>;
  /** 멘토 사용자 아이디 */
  id: Scalars['ID'];
  /** 멘토 인증 상태 */
  mentorAuthenticationStatus?: InputMaybe<MentorAuthenticationStatus>;
  /** 멘토 해제 사유 */
  mentorRejectionReason?: InputMaybe<Scalars['String']>;
  /** 멘토 인증 반려 사유 */
  mentorRevokeReason?: InputMaybe<Scalars['String']>;
  /** 멘토 기업 이름 */
  organizationNameInReview?: InputMaybe<Scalars['String']>;
  /** 멘토 전화번호 */
  phoneNumberInReview?: InputMaybe<Scalars['String']>;
  /** 멘토 최종학력 이름 */
  schoolNameInReview?: InputMaybe<Scalars['String']>;
};

export type UpdateMentorUserStatusPayload = {
  __typename?: 'UpdateMentorUserStatusPayload';
  user: User;
};

/** 기업 그룹 수정할 때 보내야 하는 객체 */
export type UpdateOrganizationGroupInput = {
  id: Scalars['ID'];
  organizationIds: Array<Scalars['ID']>;
};

/** 기업 그룹 수정할 때 반환하는 객체 */
export type UpdateOrganizationGroupPayload = {
  __typename?: 'UpdateOrganizationGroupPayload';
  organizationGroup: OrganizationGroup;
};

/** 리워드 지급 금액 설정을 업데이트할 때 보내야 하는 객체 */
export type UpdateRewardAmountSettingsInput = {
  /** 채택된 댓글에 지급되는 금액 */
  forAcceptedComment?: InputMaybe<Scalars['Int']>;
  /** 직무명이 일치하는 댓글에 지급되는 금액 */
  forDutyNameMatchedComment?: InputMaybe<Scalars['Int']>;
  /** 기업명이 일치히는 댓글에 지급되는 금액 */
  forOrganizationNameMatchedComment?: InputMaybe<Scalars['Int']>;
  /** 학교명이 일치하는 댓글에 지급되는 금액 */
  forSchoolNameMatchedComment?: InputMaybe<Scalars['Int']>;
};

/** 리워드 지급 금액 설정을 업데이트할 때 반환하는 객체 */
export type UpdateRewardAmountSettingsPayload = {
  __typename?: 'UpdateRewardAmountSettingsPayload';
  rewardAmountSettings: RewardAmountSettings;
};

export type UpdateUserInput = {
  profileURL: Scalars['String'];
};

export enum CacheControlScope {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC'
}

export type CommentReportConnection = {
  __typename?: 'CommentReportConnection';
  nodes?: Maybe<Array<Comment>>;
  totalCount: Scalars['Int'];
};

export type CommentUpdateInput = {
  attachmentIDs?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['String'];
};

export type JsonLd_CbtExam = EmbeddableJsonLd & {
  __typename?: 'JsonLd_CbtExam';
  _id: Scalars['String'];
  _type: EmbeddableJsonLdType;
  examId: Scalars['String'];
  examName: Scalars['String'];
  examRound: Scalars['String'];
  isKatex: Scalars['Boolean'];
  problem: Scalars['String'];
  problemNum: Scalars['String'];
  problemText: Scalars['String'];
  status: Scalars['String'];
};

export type MenuOrder = {
  direction: OrderDirection;
  field: MenuOrderField;
};

export enum MenuOrderField {
  ORDER = 'ORDER'
}

export type GqlBestPostListQueryVariables = Exact<{
  input: BestPostListInput;
}>;


export type GqlBestPostListQuery = { __typename?: 'Query', bestPostList?: { __typename?: 'BestPostListPayload', bestPosts?: Array<{ __typename?: 'Post', id: string, title?: string | null, url?: string | null, commentCount: number }> | null } | null };

export type GqlCbtBestPostListQueryVariables = Exact<{
  input: BestPostListInput;
}>;


export type GqlCbtBestPostListQuery = { __typename?: 'Query', bestPostList?: { __typename?: 'BestPostListPayload', bestPosts?: Array<{ __typename?: 'Post', id: string, title?: string | null, url?: string | null, commentCount: number }> | null } | null };

export type GsatReviewPost_PostFragment = { __typename?: 'Post', id: string, title?: string | null, username?: string | null, board?: { __typename?: 'Board', slug: string } | null };

export type GqlPostsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  orderBy?: InputMaybe<PostOrder>;
  filterBy?: InputMaybe<PostFiltersInput>;
}>;


export type GqlPostsQuery = { __typename?: 'Query', posts?: { __typename?: 'PostConnection', totalCount: number, nodes?: Array<{ __typename?: 'Post', id: string, title?: string | null, username?: string | null, board?: { __typename?: 'Board', slug: string } | null }> | null } | null };

export type MenuItemData_MenuFragment = { __typename?: 'Menu', id: string, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, viewerHasBookmarked: boolean, hasNewPost: boolean, board?: { __typename?: 'Board', isMentorBoard: boolean } | null };

export type GqlAddBookmarkMutationVariables = Exact<{
  menuId?: InputMaybe<Scalars['ID']>;
  addBookmarkId?: InputMaybe<Scalars['ID']>;
}>;


export type GqlAddBookmarkMutation = { __typename?: 'Mutation', addBookmark?: { __typename?: 'AddBookmarkPayload', bookmarkable?: { __typename?: 'Menu', id: string, viewerHasBookmarked: boolean, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, hasNewPost: boolean, board?: { __typename?: 'Board', isMentorBoard: boolean } | null } | null } | null };

export type GqlRemoveBookmarkMutationVariables = Exact<{
  menuId?: InputMaybe<Scalars['ID']>;
  removeBookmarkId?: InputMaybe<Scalars['ID']>;
}>;


export type GqlRemoveBookmarkMutation = { __typename?: 'Mutation', removeBookmark?: { __typename?: 'RemoveBookmarkPayload', bookmarkable?: { __typename?: 'Menu', id: string, viewerHasBookmarked: boolean, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, hasNewPost: boolean, board?: { __typename?: 'Board', isMentorBoard: boolean } | null } | null } | null };

export type GqlBookmarksQueryVariables = Exact<{
  orderBy?: InputMaybe<BookmarkOrder>;
  pagination?: InputMaybe<Pagination>;
  filterBy?: InputMaybe<BookmarkFilter>;
}>;


export type GqlBookmarksQuery = { __typename?: 'Query', bookmarks: { __typename?: 'BookmarkableConnection', totalCount: number, nodes: Array<{ __typename?: 'Menu', id: string, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, viewerHasBookmarked: boolean, hasNewPost: boolean, board?: { __typename?: 'Board', isMentorBoard: boolean } | null }> } };

export type GqlMenuQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlMenuQuery = { __typename?: 'Query', menu?: { __typename?: 'Menu', id: string, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, viewerHasBookmarked: boolean, hasNewPost: boolean, subMenus: Array<{ __typename?: 'Menu', id: string, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, viewerHasBookmarked: boolean, hasNewPost: boolean, subMenus: Array<{ __typename?: 'Menu', id: string, order?: number | null, title?: string | null, slug?: string | null, updatedAt?: number | null, isShortcut: boolean, bookmarkable: boolean, viewerHasBookmarked: boolean, hasNewPost: boolean, board?: { __typename?: 'Board', isMentorBoard: boolean } | null }>, board?: { __typename?: 'Board', isMentorBoard: boolean } | null }>, board?: { __typename?: 'Board', isMentorBoard: boolean } | null } | null };

export type MentorApprove_UserFragment = { __typename?: 'User', id: string, dutyName?: string | null, organizationName?: string | null, schoolName?: string | null, organizationNameInReview?: string | null, phoneNumberInReview?: string | null, schoolNameInReview?: string | null, dutyNameInReview?: string | null, isMentorPending: boolean, isMentorUpdateInReview: boolean, phoneNumber?: string | null, dutyInReview?: { __typename?: 'MentorDuty', id: string } | null };

export type ProfileMentor_UserFragment = { __typename?: 'User', id: string, mentorAuthenticationStatus?: MentorAuthenticationStatus | null, mentorRejectionReason?: string | null, acceptedMentorCommentCount: number, weeklyMentorCommentAcceptedRate: number, totalCommentCount: number, mentorCommentAcceptedRate: number, dutyName?: string | null, organizationName?: string | null, schoolName?: string | null, organizationNameInReview?: string | null, phoneNumberInReview?: string | null, schoolNameInReview?: string | null, dutyNameInReview?: string | null, isMentorPending: boolean, isMentorUpdateInReview: boolean, phoneNumber?: string | null, dutyInReview?: { __typename?: 'MentorDuty', id: string } | null };

export type MentorActivityHistoryListItem_CommentFragment = { __typename?: 'Comment', id: string, contentText?: string | null, fullContentText?: string | null, viewerCanAcceptComment: boolean, menteeAcceptedAt?: number | null, createdAt: number, childCommentCount: number, post?: { __typename?: 'Post', id: string, title?: string | null } | null, board?: { __typename?: 'Board', id: string, title: string, slug: string } | null };

export type ApproveInputUserInfoItem_MentorSchoolFragment = { __typename?: 'MentorSchool', id: string, name: string };

export type DocumentCertification_MentorDocumentFragment = { __typename?: 'MentorDocument', id: string, name: string, size: number, type: string, path?: string | null, url: string };

export type ApproveInputUserInfoItem_UserFragment = { __typename?: 'User', id: string, organizationName?: string | null, dutyName?: string | null, schoolName?: string | null };

export type ReplyTableItem_PostFragment = { __typename?: 'Post', id: string, title?: string | null, commentCount: number, nickname?: string | null, createdAt: number, views: number, scrapCount: number, likes: number, imageSrc?: string | null, remainingAcceptableCommentCount?: number | null, isViewerMatchingOrganization: boolean, isViewerMatchingMentorDuty: boolean, isViewerMatchingMentorSchool: boolean, isViewerAvailableAcceptReward: boolean, isViewerAvailableMatchingReward: boolean, organizations?: Array<{ __typename?: 'Organization', id: string, name: string }> | null, schools?: Array<{ __typename?: 'MentorSchool', id: string, name: string }> | null, duties?: Array<{ __typename?: 'MentorDuty', id: string, name: string }> | null, board?: { __typename?: 'Board', id: string, slug: string } | null };

export type ReplyTable_RewardAmountSettingsFragment = { __typename?: 'RewardAmountSettings', id: string, forOrganizationNameMatchedComment: number, forDutyNameMatchedComment: number, forSchoolNameMatchedComment: number, forAcceptedComment: number, createdAt: number, updatedAt?: number | null };

export type MentorRewardContext_MentorRewardLogFragment = { __typename?: 'MentorRewardLog', id: string, userRewardAmount: number, reason: RewardReason, amount: number, createdAt: number, comment?: { __typename?: 'Comment', id: string, contentText?: string | null, post?: { __typename?: 'Post', id: string, board?: { __typename?: 'Board', slug: string } | null } | null } | null };

export type RewardDetail_UserFragment = { __typename?: 'User', id: string, rewardAmount: number, monthlyExpiringRewardAmount: number, monthlyRequestCashoutLimitAmount: number, monthlyRequestedCashoutAmount: number };

export type MentorBoardListItem_BoardFragment = { __typename?: 'Board', id: string, title: string, menu?: { __typename?: 'Menu', id: string, bookmarkable: boolean, hasNewPost: boolean, isShortcut: boolean, slug?: string | null, viewerHasBookmarked: boolean } | null };

export type GqlCommunityMentorDocumentPrepareUploadMutationVariables = Exact<{
  input: MentorDocumentPrepareUploadInput;
}>;


export type GqlCommunityMentorDocumentPrepareUploadMutation = { __typename?: 'Mutation', mentorDocumentPrepareUpload: { __typename?: 'MentorDocumentPrepareUploadPayload', signedUrl: string, mentorDocument: { __typename?: 'MentorDocument', id: string, name: string, size: number, type: string, path?: string | null, url: string } } };

export type GqlCommunityMentorDocumentCompleteUploadMutationVariables = Exact<{
  mentorDocumentCompleteUploadId: Scalars['ID'];
}>;


export type GqlCommunityMentorDocumentCompleteUploadMutation = { __typename?: 'Mutation', mentorDocumentCompleteUpload: { __typename?: 'MentorDocumentCompleteUploadPayload', mentorDocument: { __typename?: 'MentorDocument', id: string, name: string, size: number, type: string, path?: string | null, url: string } } };

export type GqlCommunityDeleteMentorDocumentMutationVariables = Exact<{
  deleteMentorDocumentId: Scalars['ID'];
}>;


export type GqlCommunityDeleteMentorDocumentMutation = { __typename?: 'Mutation', deleteMentorDocument: { __typename?: 'DeleteMentorDocumentPayload', mentorDocument: { __typename?: 'MentorDocument', id: string, name: string, size: number, type: string, path?: string | null, url: string } } };

export type GqlCommunityAuthenticateWithDocumentsMutationVariables = Exact<{
  input: AuthenticateWithDocumentsInput;
}>;


export type GqlCommunityAuthenticateWithDocumentsMutation = { __typename?: 'Mutation', authenticateWithDocuments: { __typename?: 'AuthenticateWithDocumentsPayloadDto', user: { __typename?: 'User', id: string, organizationName?: string | null, dutyName?: string | null, schoolName?: string | null, mentorAuthenticationDocuments: { __typename?: 'MentorDocumentConnection', totalCount: number, nodes: Array<{ __typename?: 'MentorDocument', id: string, name: string, size: number, type: string, path?: string | null, url: string }> } } } };

export type GqlCommunityAuthenticateEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GqlCommunityAuthenticateEmailMutation = { __typename?: 'Mutation', authenticateEmail: { __typename?: 'AuthenticateEmailPayload', authenticationToken: string } };

export type GqlCommunityAuthenticateWithEmailMutationVariables = Exact<{
  input: AuthenticateWithEmailInput;
}>;


export type GqlCommunityAuthenticateWithEmailMutation = { __typename?: 'Mutation', authenticateWithEmail: { __typename?: 'AuthenticateWithEmailPayload', user: { __typename?: 'User', id: string, mentorAuthenticationStatus?: MentorAuthenticationStatus | null, mentorRejectionReason?: string | null, acceptedMentorCommentCount: number, weeklyMentorCommentAcceptedRate: number, totalCommentCount: number, mentorCommentAcceptedRate: number, dutyName?: string | null, organizationName?: string | null, schoolName?: string | null, organizationNameInReview?: string | null, phoneNumberInReview?: string | null, schoolNameInReview?: string | null, dutyNameInReview?: string | null, isMentorPending: boolean, isMentorUpdateInReview: boolean, phoneNumber?: string | null, dutyInReview?: { __typename?: 'MentorDuty', id: string } | null } } };

export type GqlCommunityRequestCashoutMutationVariables = Exact<{
  input: RequestCashoutInput;
}>;


export type GqlCommunityRequestCashoutMutation = { __typename?: 'Mutation', requestCashout: { __typename?: 'RequestCashoutPayload', requestCashoutLog: { __typename?: 'RequestCashoutLog', amount: number, id: string, realName: string, phoneNumber: string, bank: Bank, bankAccountNumber: string, createdAt: number } } };

export type GqlCommunityCurrentUserQueryVariables = Exact<{
  isMypage?: Scalars['Boolean'];
}>;


export type GqlCommunityCurrentUserQuery = { __typename?: 'Query', messageUnreadCount: number, currentUser?: { __typename?: 'User', id: string, nickname?: string | null, mentorAuthenticationStatus?: MentorAuthenticationStatus | null, mentorRejectionReason?: string | null, acceptedMentorCommentCount: number, weeklyMentorCommentAcceptedRate: number, totalCommentCount: number, mentorCommentAcceptedRate: number, rewardAmount: number, monthlyExpiringRewardAmount: number, monthlyRequestCashoutLimitAmount: number, monthlyRequestedCashoutAmount: number, dutyName?: string | null, organizationName?: string | null, schoolName?: string | null, organizationNameInReview?: string | null, phoneNumberInReview?: string | null, schoolNameInReview?: string | null, dutyNameInReview?: string | null, isMentorPending: boolean, isMentorUpdateInReview: boolean, phoneNumber?: string | null, dutyInReview?: { __typename?: 'MentorDuty', id: string } | null } | null };

export type MentorDutiesBase_MentorDutyFragment = { __typename?: 'MentorDuty', id: string, name: string };

export type SearchMentorDuties_MentorDutyFragment = { __typename?: 'MentorDuty', id: string, name: string, children: Array<{ __typename?: 'MentorDuty', id: string, name: string }>, parent?: { __typename?: 'MentorDuty', id: string, name: string, parent?: { __typename?: 'MentorDuty', id: string, name: string } | null } | null };

export type GqlSearchMentorDutiesQueryVariables = Exact<{
  filterBy: SearchMentorDutiesFilters;
}>;


export type GqlSearchMentorDutiesQuery = { __typename?: 'Query', searchMentorDuties: Array<{ __typename?: 'MentorDuty', id: string, name: string, children: Array<{ __typename?: 'MentorDuty', id: string, name: string }>, parent?: { __typename?: 'MentorDuty', id: string, name: string, parent?: { __typename?: 'MentorDuty', id: string, name: string } | null } | null }> };

export type GqlCommunityMentorSchoolByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GqlCommunityMentorSchoolByNameQuery = { __typename?: 'Query', mentorSchoolByName: Array<{ __typename?: 'MentorSchool', id: string, name: string }> };

export type GqlCommunityViewerRecommendedMentorPostsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
  filterBy?: InputMaybe<ViewerRecommendedMentorPostFilters>;
}>;


export type GqlCommunityViewerRecommendedMentorPostsQuery = { __typename?: 'Query', viewerRecommendedMentorPosts: { __typename?: 'PostConnection', totalCount: number, nodes?: Array<{ __typename?: 'Post', id: string, title?: string | null, commentCount: number, nickname?: string | null, createdAt: number, views: number, scrapCount: number, likes: number, imageSrc?: string | null, remainingAcceptableCommentCount?: number | null, isViewerMatchingOrganization: boolean, isViewerMatchingMentorDuty: boolean, isViewerMatchingMentorSchool: boolean, isViewerAvailableAcceptReward: boolean, isViewerAvailableMatchingReward: boolean, organizations?: Array<{ __typename?: 'Organization', id: string, name: string }> | null, schools?: Array<{ __typename?: 'MentorSchool', id: string, name: string }> | null, duties?: Array<{ __typename?: 'MentorDuty', id: string, name: string }> | null, board?: { __typename?: 'Board', id: string, slug: string } | null }> | null } };

export type GqlCommunityViewerMentorCommentsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type GqlCommunityViewerMentorCommentsQuery = { __typename?: 'Query', viewerMentorComments: { __typename?: 'CommentConnection', totalCount: number, nodes: Array<{ __typename?: 'Comment', id: string, contentText?: string | null, fullContentText?: string | null, viewerCanAcceptComment: boolean, menteeAcceptedAt?: number | null, createdAt: number, childCommentCount: number, post?: { __typename?: 'Post', id: string, title?: string | null } | null, board?: { __typename?: 'Board', id: string, title: string, slug: string } | null }> } };

export type GqlCommunityViewerMentorRewardLogsQueryVariables = Exact<{
  pagination?: InputMaybe<Pagination>;
}>;


export type GqlCommunityViewerMentorRewardLogsQuery = { __typename?: 'Query', viewerMentorRewardLogs: { __typename?: 'MentorRewardLogConnection', totalCount: number, nodes: Array<{ __typename?: 'MentorRewardLog', id: string, userRewardAmount: number, reason: RewardReason, amount: number, createdAt: number, comment?: { __typename?: 'Comment', id: string, contentText?: string | null, post?: { __typename?: 'Post', id: string, board?: { __typename?: 'Board', slug: string } | null } | null } | null }> } };

export type GqlCommunityMentorRewardAmountSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCommunityMentorRewardAmountSettingsQuery = { __typename?: 'Query', mentorRewardAmountSettings: { __typename?: 'RewardAmountSettings', id: string, forOrganizationNameMatchedComment: number, forDutyNameMatchedComment: number, forSchoolNameMatchedComment: number, forAcceptedComment: number, createdAt: number, updatedAt?: number | null } };

export type GqlCommunityMentorBoardsQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCommunityMentorBoardsQuery = { __typename?: 'Query', mentorBoards: { __typename?: 'BoardConnection', totalCount: number, nodes: Array<{ __typename?: 'Board', id: string, title: string, menu?: { __typename?: 'Menu', id: string, bookmarkable: boolean, hasNewPost: boolean, isShortcut: boolean, slug?: string | null, viewerHasBookmarked: boolean } | null }> } };

export type GqlCommunityReplyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GqlCommunityReplyCountQuery = { __typename?: 'Query', viewerRecommendedMentorPosts: { __typename?: 'PostConnection', totalCount: number } };

export type UseGetBoardBySlug_PostConnectionFragment = { __typename?: 'PostConnection', nodes?: Array<{ __typename?: 'Post', id: string, title?: string | null, contentText?: string | null, commentCount: number }> | null };

export type UseGetBoardBySlug_PostFragment = { __typename?: 'Post', id: string, title?: string | null, contentText?: string | null, commentCount: number };

export type UseGetBoardBySlug_BoardFragment = { __typename?: 'Board', id: string, menu?: { __typename?: 'Menu', id: string, title?: string | null, slug?: string | null, hasNewPost: boolean } | null };

export type GqlBoardBySlugQueryVariables = Exact<{
  boardSlug: Scalars['String'];
  orderBy?: InputMaybe<PostOrder>;
  pagination?: InputMaybe<Pagination>;
}>;


export type GqlBoardBySlugQuery = { __typename?: 'Query', boardBySlug?: { __typename?: 'Board', id: string, posts?: { __typename?: 'PostConnection', totalCount: number, nodes?: Array<{ __typename?: 'Post', id: string, title?: string | null, contentText?: string | null, commentCount: number }> | null } | null, menu?: { __typename?: 'Menu', id: string, title?: string | null, slug?: string | null, hasNewPost: boolean } | null } | null };

export const GsatReviewPost_PostFragmentDoc = gql`
    fragment GsatReviewPost_post on Post {
  id
  title
  username
  board {
    slug
  }
}
    `;
export const MenuItemData_MenuFragmentDoc = gql`
    fragment MenuItemData_menu on Menu {
  id
  order
  title
  slug
  updatedAt
  isShortcut
  bookmarkable
  viewerHasBookmarked
  hasNewPost
  board {
    isMentorBoard
  }
}
    `;
export const MentorApprove_UserFragmentDoc = gql`
    fragment MentorApprove_user on User {
  id
  dutyName
  organizationName
  schoolName
  organizationNameInReview
  phoneNumberInReview
  schoolNameInReview
  dutyInReview {
    id
  }
  dutyNameInReview
  isMentorPending
  isMentorUpdateInReview
  phoneNumber
}
    `;
export const ProfileMentor_UserFragmentDoc = gql`
    fragment ProfileMentor_user on User {
  id
  mentorAuthenticationStatus
  mentorRejectionReason
  acceptedMentorCommentCount
  weeklyMentorCommentAcceptedRate
  totalCommentCount
  mentorCommentAcceptedRate
  ...MentorApprove_user
}
    ${MentorApprove_UserFragmentDoc}`;
export const MentorActivityHistoryListItem_CommentFragmentDoc = gql`
    fragment MentorActivityHistoryListItem_comment on Comment {
  id
  contentText
  fullContentText
  viewerCanAcceptComment
  menteeAcceptedAt
  createdAt
  childCommentCount
  post {
    id
    title
  }
  board {
    id
    title
    slug
  }
}
    `;
export const ApproveInputUserInfoItem_MentorSchoolFragmentDoc = gql`
    fragment ApproveInputUserInfoItem_mentorSchool on MentorSchool {
  id
  name
}
    `;
export const DocumentCertification_MentorDocumentFragmentDoc = gql`
    fragment DocumentCertification_mentorDocument on MentorDocument {
  id
  name
  size
  type
  path
  url
}
    `;
export const ApproveInputUserInfoItem_UserFragmentDoc = gql`
    fragment ApproveInputUserInfoItem_user on User {
  id
  organizationName
  dutyName
  schoolName
}
    `;
export const ReplyTableItem_PostFragmentDoc = gql`
    fragment ReplyTableItem_post on Post {
  id
  title
  organizations {
    id
    name
  }
  schools {
    id
    name
  }
  duties {
    id
    name
  }
  commentCount
  nickname
  createdAt
  views
  scrapCount
  likes
  imageSrc
  remainingAcceptableCommentCount
  isViewerMatchingOrganization
  isViewerMatchingMentorDuty
  isViewerMatchingMentorSchool
  isViewerAvailableAcceptReward
  isViewerAvailableMatchingReward
  board {
    id
    slug
  }
}
    `;
export const ReplyTable_RewardAmountSettingsFragmentDoc = gql`
    fragment ReplyTable_rewardAmountSettings on RewardAmountSettings {
  id
  forOrganizationNameMatchedComment
  forDutyNameMatchedComment
  forSchoolNameMatchedComment
  forAcceptedComment
  createdAt
  updatedAt
}
    `;
export const MentorRewardContext_MentorRewardLogFragmentDoc = gql`
    fragment MentorRewardContext_mentorRewardLog on MentorRewardLog {
  id
  userRewardAmount
  reason
  amount
  createdAt
  comment {
    id
    contentText
    post {
      id
      board {
        slug
      }
    }
  }
}
    `;
export const RewardDetail_UserFragmentDoc = gql`
    fragment RewardDetail_user on User {
  id
  rewardAmount
  monthlyExpiringRewardAmount
  monthlyRequestCashoutLimitAmount
  monthlyRequestedCashoutAmount
}
    `;
export const MentorBoardListItem_BoardFragmentDoc = gql`
    fragment MentorBoardListItem_board on Board {
  id
  title
  menu {
    id
    bookmarkable
    hasNewPost
    isShortcut
    slug
    viewerHasBookmarked
  }
}
    `;
export const MentorDutiesBase_MentorDutyFragmentDoc = gql`
    fragment MentorDutiesBase_mentorDuty on MentorDuty {
  id
  name
}
    `;
export const SearchMentorDuties_MentorDutyFragmentDoc = gql`
    fragment SearchMentorDuties_mentorDuty on MentorDuty {
  children {
    ...MentorDutiesBase_mentorDuty
  }
  parent {
    ...MentorDutiesBase_mentorDuty
    parent {
      ...MentorDutiesBase_mentorDuty
    }
  }
  ...MentorDutiesBase_mentorDuty
}
    ${MentorDutiesBase_MentorDutyFragmentDoc}`;
export const UseGetBoardBySlug_PostFragmentDoc = gql`
    fragment useGetBoardBySlug_Post on Post {
  id
  title
  contentText
  commentCount
}
    `;
export const UseGetBoardBySlug_PostConnectionFragmentDoc = gql`
    fragment useGetBoardBySlug_PostConnection on PostConnection {
  nodes {
    ...useGetBoardBySlug_Post
  }
}
    ${UseGetBoardBySlug_PostFragmentDoc}`;
export const UseGetBoardBySlug_BoardFragmentDoc = gql`
    fragment useGetBoardBySlug_Board on Board {
  id
  menu {
    id
    title
    slug
    hasNewPost
  }
}
    `;
export const GqlBestPostListDocument = gql`
    query gqlBestPostList($input: BestPostListInput!) {
  bestPostList(input: $input) {
    bestPosts {
      id
      title
      url
      commentCount
    }
  }
}
    `;

/**
 * __useGqlBestPostListQuery__
 *
 * To run a query within a React component, call `useGqlBestPostListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlBestPostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlBestPostListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlBestPostListQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GqlBestPostListQuery, GqlBestPostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlBestPostListQuery, GqlBestPostListQueryVariables>(GqlBestPostListDocument, options);
      }
export function useGqlBestPostListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlBestPostListQuery, GqlBestPostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlBestPostListQuery, GqlBestPostListQueryVariables>(GqlBestPostListDocument, options);
        }
export type GqlBestPostListQueryHookResult = ReturnType<typeof useGqlBestPostListQuery>;
export type GqlBestPostListLazyQueryHookResult = ReturnType<typeof useGqlBestPostListLazyQuery>;
export type GqlBestPostListQueryResult = Apollo.QueryResult<GqlBestPostListQuery, GqlBestPostListQueryVariables>;
export const GqlCbtBestPostListDocument = gql`
    query gqlCbtBestPostList($input: BestPostListInput!) {
  bestPostList(input: $input) {
    bestPosts {
      id
      title
      url
      commentCount
    }
  }
}
    `;

/**
 * __useGqlCbtBestPostListQuery__
 *
 * To run a query within a React component, call `useGqlCbtBestPostListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCbtBestPostListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCbtBestPostListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlCbtBestPostListQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GqlCbtBestPostListQuery, GqlCbtBestPostListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCbtBestPostListQuery, GqlCbtBestPostListQueryVariables>(GqlCbtBestPostListDocument, options);
      }
export function useGqlCbtBestPostListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCbtBestPostListQuery, GqlCbtBestPostListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCbtBestPostListQuery, GqlCbtBestPostListQueryVariables>(GqlCbtBestPostListDocument, options);
        }
export type GqlCbtBestPostListQueryHookResult = ReturnType<typeof useGqlCbtBestPostListQuery>;
export type GqlCbtBestPostListLazyQueryHookResult = ReturnType<typeof useGqlCbtBestPostListLazyQuery>;
export type GqlCbtBestPostListQueryResult = Apollo.QueryResult<GqlCbtBestPostListQuery, GqlCbtBestPostListQueryVariables>;
export const GqlPostsDocument = gql`
    query gqlPosts($pagination: Pagination, $orderBy: PostOrder, $filterBy: PostFiltersInput) {
  posts(pagination: $pagination, orderBy: $orderBy, filterBy: $filterBy) {
    totalCount
    nodes {
      ...GsatReviewPost_post
    }
  }
}
    ${GsatReviewPost_PostFragmentDoc}`;

/**
 * __useGqlPostsQuery__
 *
 * To run a query within a React component, call `useGqlPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGqlPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlPostsQuery, GqlPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlPostsQuery, GqlPostsQueryVariables>(GqlPostsDocument, options);
      }
export function useGqlPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlPostsQuery, GqlPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlPostsQuery, GqlPostsQueryVariables>(GqlPostsDocument, options);
        }
export type GqlPostsQueryHookResult = ReturnType<typeof useGqlPostsQuery>;
export type GqlPostsLazyQueryHookResult = ReturnType<typeof useGqlPostsLazyQuery>;
export type GqlPostsQueryResult = Apollo.QueryResult<GqlPostsQuery, GqlPostsQueryVariables>;
export const GqlAddBookmarkDocument = gql`
    mutation gqlAddBookmark($menuId: ID, $addBookmarkId: ID) {
  addBookmark(menuID: $menuId, id: $addBookmarkId) {
    bookmarkable {
      id
      viewerHasBookmarked
      ... on Menu {
        ...MenuItemData_menu
      }
    }
  }
}
    ${MenuItemData_MenuFragmentDoc}`;
export type GqlAddBookmarkMutationFn = Apollo.MutationFunction<GqlAddBookmarkMutation, GqlAddBookmarkMutationVariables>;

/**
 * __useGqlAddBookmarkMutation__
 *
 * To run a mutation, you first call `useGqlAddBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlAddBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlAddBookmarkMutation, { data, loading, error }] = useGqlAddBookmarkMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      addBookmarkId: // value for 'addBookmarkId'
 *   },
 * });
 */
export function useGqlAddBookmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlAddBookmarkMutation, GqlAddBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlAddBookmarkMutation, GqlAddBookmarkMutationVariables>(GqlAddBookmarkDocument, options);
      }
export type GqlAddBookmarkMutationHookResult = ReturnType<typeof useGqlAddBookmarkMutation>;
export type GqlAddBookmarkMutationResult = Apollo.MutationResult<GqlAddBookmarkMutation>;
export type GqlAddBookmarkMutationOptions = Apollo.BaseMutationOptions<GqlAddBookmarkMutation, GqlAddBookmarkMutationVariables>;
export const GqlRemoveBookmarkDocument = gql`
    mutation gqlRemoveBookmark($menuId: ID, $removeBookmarkId: ID) {
  removeBookmark(menuID: $menuId, id: $removeBookmarkId) {
    bookmarkable {
      id
      viewerHasBookmarked
      ... on Menu {
        ...MenuItemData_menu
      }
    }
  }
}
    ${MenuItemData_MenuFragmentDoc}`;
export type GqlRemoveBookmarkMutationFn = Apollo.MutationFunction<GqlRemoveBookmarkMutation, GqlRemoveBookmarkMutationVariables>;

/**
 * __useGqlRemoveBookmarkMutation__
 *
 * To run a mutation, you first call `useGqlRemoveBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlRemoveBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlRemoveBookmarkMutation, { data, loading, error }] = useGqlRemoveBookmarkMutation({
 *   variables: {
 *      menuId: // value for 'menuId'
 *      removeBookmarkId: // value for 'removeBookmarkId'
 *   },
 * });
 */
export function useGqlRemoveBookmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlRemoveBookmarkMutation, GqlRemoveBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlRemoveBookmarkMutation, GqlRemoveBookmarkMutationVariables>(GqlRemoveBookmarkDocument, options);
      }
export type GqlRemoveBookmarkMutationHookResult = ReturnType<typeof useGqlRemoveBookmarkMutation>;
export type GqlRemoveBookmarkMutationResult = Apollo.MutationResult<GqlRemoveBookmarkMutation>;
export type GqlRemoveBookmarkMutationOptions = Apollo.BaseMutationOptions<GqlRemoveBookmarkMutation, GqlRemoveBookmarkMutationVariables>;
export const GqlBookmarksDocument = gql`
    query gqlBookmarks($orderBy: BookmarkOrder, $pagination: Pagination, $filterBy: BookmarkFilter) {
  bookmarks(orderBy: $orderBy, pagination: $pagination, filterBy: $filterBy) {
    nodes {
      ... on Menu {
        ...MenuItemData_menu
      }
    }
    totalCount
  }
}
    ${MenuItemData_MenuFragmentDoc}`;

/**
 * __useGqlBookmarksQuery__
 *
 * To run a query within a React component, call `useGqlBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlBookmarksQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGqlBookmarksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlBookmarksQuery, GqlBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlBookmarksQuery, GqlBookmarksQueryVariables>(GqlBookmarksDocument, options);
      }
export function useGqlBookmarksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlBookmarksQuery, GqlBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlBookmarksQuery, GqlBookmarksQueryVariables>(GqlBookmarksDocument, options);
        }
export type GqlBookmarksQueryHookResult = ReturnType<typeof useGqlBookmarksQuery>;
export type GqlBookmarksLazyQueryHookResult = ReturnType<typeof useGqlBookmarksLazyQuery>;
export type GqlBookmarksQueryResult = Apollo.QueryResult<GqlBookmarksQuery, GqlBookmarksQueryVariables>;
export const GqlMenuDocument = gql`
    query gqlMenu {
  menu {
    ...MenuItemData_menu
    subMenus {
      ...MenuItemData_menu
      subMenus {
        ...MenuItemData_menu
      }
    }
  }
}
    ${MenuItemData_MenuFragmentDoc}`;

/**
 * __useGqlMenuQuery__
 *
 * To run a query within a React component, call `useGqlMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlMenuQuery({
 *   variables: {
 *   },
 * });
 */
export function useGqlMenuQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlMenuQuery, GqlMenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlMenuQuery, GqlMenuQueryVariables>(GqlMenuDocument, options);
      }
export function useGqlMenuLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlMenuQuery, GqlMenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlMenuQuery, GqlMenuQueryVariables>(GqlMenuDocument, options);
        }
export type GqlMenuQueryHookResult = ReturnType<typeof useGqlMenuQuery>;
export type GqlMenuLazyQueryHookResult = ReturnType<typeof useGqlMenuLazyQuery>;
export type GqlMenuQueryResult = Apollo.QueryResult<GqlMenuQuery, GqlMenuQueryVariables>;
export const GqlCommunityMentorDocumentPrepareUploadDocument = gql`
    mutation gqlCommunityMentorDocumentPrepareUpload($input: MentorDocumentPrepareUploadInput!) {
  mentorDocumentPrepareUpload(input: $input) {
    mentorDocument {
      ...DocumentCertification_mentorDocument
    }
    signedUrl
  }
}
    ${DocumentCertification_MentorDocumentFragmentDoc}`;
export type GqlCommunityMentorDocumentPrepareUploadMutationFn = Apollo.MutationFunction<GqlCommunityMentorDocumentPrepareUploadMutation, GqlCommunityMentorDocumentPrepareUploadMutationVariables>;

/**
 * __useGqlCommunityMentorDocumentPrepareUploadMutation__
 *
 * To run a mutation, you first call `useGqlCommunityMentorDocumentPrepareUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityMentorDocumentPrepareUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityMentorDocumentPrepareUploadMutation, { data, loading, error }] = useGqlCommunityMentorDocumentPrepareUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlCommunityMentorDocumentPrepareUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityMentorDocumentPrepareUploadMutation, GqlCommunityMentorDocumentPrepareUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityMentorDocumentPrepareUploadMutation, GqlCommunityMentorDocumentPrepareUploadMutationVariables>(GqlCommunityMentorDocumentPrepareUploadDocument, options);
      }
export type GqlCommunityMentorDocumentPrepareUploadMutationHookResult = ReturnType<typeof useGqlCommunityMentorDocumentPrepareUploadMutation>;
export type GqlCommunityMentorDocumentPrepareUploadMutationResult = Apollo.MutationResult<GqlCommunityMentorDocumentPrepareUploadMutation>;
export type GqlCommunityMentorDocumentPrepareUploadMutationOptions = Apollo.BaseMutationOptions<GqlCommunityMentorDocumentPrepareUploadMutation, GqlCommunityMentorDocumentPrepareUploadMutationVariables>;
export const GqlCommunityMentorDocumentCompleteUploadDocument = gql`
    mutation gqlCommunityMentorDocumentCompleteUpload($mentorDocumentCompleteUploadId: ID!) {
  mentorDocumentCompleteUpload(id: $mentorDocumentCompleteUploadId) {
    mentorDocument {
      ...DocumentCertification_mentorDocument
    }
  }
}
    ${DocumentCertification_MentorDocumentFragmentDoc}`;
export type GqlCommunityMentorDocumentCompleteUploadMutationFn = Apollo.MutationFunction<GqlCommunityMentorDocumentCompleteUploadMutation, GqlCommunityMentorDocumentCompleteUploadMutationVariables>;

/**
 * __useGqlCommunityMentorDocumentCompleteUploadMutation__
 *
 * To run a mutation, you first call `useGqlCommunityMentorDocumentCompleteUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityMentorDocumentCompleteUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityMentorDocumentCompleteUploadMutation, { data, loading, error }] = useGqlCommunityMentorDocumentCompleteUploadMutation({
 *   variables: {
 *      mentorDocumentCompleteUploadId: // value for 'mentorDocumentCompleteUploadId'
 *   },
 * });
 */
export function useGqlCommunityMentorDocumentCompleteUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityMentorDocumentCompleteUploadMutation, GqlCommunityMentorDocumentCompleteUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityMentorDocumentCompleteUploadMutation, GqlCommunityMentorDocumentCompleteUploadMutationVariables>(GqlCommunityMentorDocumentCompleteUploadDocument, options);
      }
export type GqlCommunityMentorDocumentCompleteUploadMutationHookResult = ReturnType<typeof useGqlCommunityMentorDocumentCompleteUploadMutation>;
export type GqlCommunityMentorDocumentCompleteUploadMutationResult = Apollo.MutationResult<GqlCommunityMentorDocumentCompleteUploadMutation>;
export type GqlCommunityMentorDocumentCompleteUploadMutationOptions = Apollo.BaseMutationOptions<GqlCommunityMentorDocumentCompleteUploadMutation, GqlCommunityMentorDocumentCompleteUploadMutationVariables>;
export const GqlCommunityDeleteMentorDocumentDocument = gql`
    mutation gqlCommunityDeleteMentorDocument($deleteMentorDocumentId: ID!) {
  deleteMentorDocument(id: $deleteMentorDocumentId) {
    mentorDocument {
      ...DocumentCertification_mentorDocument
    }
  }
}
    ${DocumentCertification_MentorDocumentFragmentDoc}`;
export type GqlCommunityDeleteMentorDocumentMutationFn = Apollo.MutationFunction<GqlCommunityDeleteMentorDocumentMutation, GqlCommunityDeleteMentorDocumentMutationVariables>;

/**
 * __useGqlCommunityDeleteMentorDocumentMutation__
 *
 * To run a mutation, you first call `useGqlCommunityDeleteMentorDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityDeleteMentorDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityDeleteMentorDocumentMutation, { data, loading, error }] = useGqlCommunityDeleteMentorDocumentMutation({
 *   variables: {
 *      deleteMentorDocumentId: // value for 'deleteMentorDocumentId'
 *   },
 * });
 */
export function useGqlCommunityDeleteMentorDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityDeleteMentorDocumentMutation, GqlCommunityDeleteMentorDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityDeleteMentorDocumentMutation, GqlCommunityDeleteMentorDocumentMutationVariables>(GqlCommunityDeleteMentorDocumentDocument, options);
      }
export type GqlCommunityDeleteMentorDocumentMutationHookResult = ReturnType<typeof useGqlCommunityDeleteMentorDocumentMutation>;
export type GqlCommunityDeleteMentorDocumentMutationResult = Apollo.MutationResult<GqlCommunityDeleteMentorDocumentMutation>;
export type GqlCommunityDeleteMentorDocumentMutationOptions = Apollo.BaseMutationOptions<GqlCommunityDeleteMentorDocumentMutation, GqlCommunityDeleteMentorDocumentMutationVariables>;
export const GqlCommunityAuthenticateWithDocumentsDocument = gql`
    mutation gqlCommunityAuthenticateWithDocuments($input: AuthenticateWithDocumentsInput!) {
  authenticateWithDocuments(input: $input) {
    user {
      id
      ...ApproveInputUserInfoItem_user
      mentorAuthenticationDocuments {
        nodes {
          ...DocumentCertification_mentorDocument
        }
        totalCount
      }
    }
  }
}
    ${ApproveInputUserInfoItem_UserFragmentDoc}
${DocumentCertification_MentorDocumentFragmentDoc}`;
export type GqlCommunityAuthenticateWithDocumentsMutationFn = Apollo.MutationFunction<GqlCommunityAuthenticateWithDocumentsMutation, GqlCommunityAuthenticateWithDocumentsMutationVariables>;

/**
 * __useGqlCommunityAuthenticateWithDocumentsMutation__
 *
 * To run a mutation, you first call `useGqlCommunityAuthenticateWithDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityAuthenticateWithDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityAuthenticateWithDocumentsMutation, { data, loading, error }] = useGqlCommunityAuthenticateWithDocumentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlCommunityAuthenticateWithDocumentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityAuthenticateWithDocumentsMutation, GqlCommunityAuthenticateWithDocumentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityAuthenticateWithDocumentsMutation, GqlCommunityAuthenticateWithDocumentsMutationVariables>(GqlCommunityAuthenticateWithDocumentsDocument, options);
      }
export type GqlCommunityAuthenticateWithDocumentsMutationHookResult = ReturnType<typeof useGqlCommunityAuthenticateWithDocumentsMutation>;
export type GqlCommunityAuthenticateWithDocumentsMutationResult = Apollo.MutationResult<GqlCommunityAuthenticateWithDocumentsMutation>;
export type GqlCommunityAuthenticateWithDocumentsMutationOptions = Apollo.BaseMutationOptions<GqlCommunityAuthenticateWithDocumentsMutation, GqlCommunityAuthenticateWithDocumentsMutationVariables>;
export const GqlCommunityAuthenticateEmailDocument = gql`
    mutation gqlCommunityAuthenticateEmail($email: String!) {
  authenticateEmail(email: $email) {
    authenticationToken
  }
}
    `;
export type GqlCommunityAuthenticateEmailMutationFn = Apollo.MutationFunction<GqlCommunityAuthenticateEmailMutation, GqlCommunityAuthenticateEmailMutationVariables>;

/**
 * __useGqlCommunityAuthenticateEmailMutation__
 *
 * To run a mutation, you first call `useGqlCommunityAuthenticateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityAuthenticateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityAuthenticateEmailMutation, { data, loading, error }] = useGqlCommunityAuthenticateEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGqlCommunityAuthenticateEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityAuthenticateEmailMutation, GqlCommunityAuthenticateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityAuthenticateEmailMutation, GqlCommunityAuthenticateEmailMutationVariables>(GqlCommunityAuthenticateEmailDocument, options);
      }
export type GqlCommunityAuthenticateEmailMutationHookResult = ReturnType<typeof useGqlCommunityAuthenticateEmailMutation>;
export type GqlCommunityAuthenticateEmailMutationResult = Apollo.MutationResult<GqlCommunityAuthenticateEmailMutation>;
export type GqlCommunityAuthenticateEmailMutationOptions = Apollo.BaseMutationOptions<GqlCommunityAuthenticateEmailMutation, GqlCommunityAuthenticateEmailMutationVariables>;
export const GqlCommunityAuthenticateWithEmailDocument = gql`
    mutation gqlCommunityAuthenticateWithEmail($input: AuthenticateWithEmailInput!) {
  authenticateWithEmail(input: $input) {
    user {
      ...ProfileMentor_user
    }
  }
}
    ${ProfileMentor_UserFragmentDoc}`;
export type GqlCommunityAuthenticateWithEmailMutationFn = Apollo.MutationFunction<GqlCommunityAuthenticateWithEmailMutation, GqlCommunityAuthenticateWithEmailMutationVariables>;

/**
 * __useGqlCommunityAuthenticateWithEmailMutation__
 *
 * To run a mutation, you first call `useGqlCommunityAuthenticateWithEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityAuthenticateWithEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityAuthenticateWithEmailMutation, { data, loading, error }] = useGqlCommunityAuthenticateWithEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlCommunityAuthenticateWithEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityAuthenticateWithEmailMutation, GqlCommunityAuthenticateWithEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityAuthenticateWithEmailMutation, GqlCommunityAuthenticateWithEmailMutationVariables>(GqlCommunityAuthenticateWithEmailDocument, options);
      }
export type GqlCommunityAuthenticateWithEmailMutationHookResult = ReturnType<typeof useGqlCommunityAuthenticateWithEmailMutation>;
export type GqlCommunityAuthenticateWithEmailMutationResult = Apollo.MutationResult<GqlCommunityAuthenticateWithEmailMutation>;
export type GqlCommunityAuthenticateWithEmailMutationOptions = Apollo.BaseMutationOptions<GqlCommunityAuthenticateWithEmailMutation, GqlCommunityAuthenticateWithEmailMutationVariables>;
export const GqlCommunityRequestCashoutDocument = gql`
    mutation gqlCommunityRequestCashout($input: RequestCashoutInput!) {
  requestCashout(input: $input) {
    requestCashoutLog {
      amount
      id
      realName
      phoneNumber
      bank
      bankAccountNumber
      createdAt
    }
  }
}
    `;
export type GqlCommunityRequestCashoutMutationFn = Apollo.MutationFunction<GqlCommunityRequestCashoutMutation, GqlCommunityRequestCashoutMutationVariables>;

/**
 * __useGqlCommunityRequestCashoutMutation__
 *
 * To run a mutation, you first call `useGqlCommunityRequestCashoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityRequestCashoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [gqlCommunityRequestCashoutMutation, { data, loading, error }] = useGqlCommunityRequestCashoutMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGqlCommunityRequestCashoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GqlCommunityRequestCashoutMutation, GqlCommunityRequestCashoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<GqlCommunityRequestCashoutMutation, GqlCommunityRequestCashoutMutationVariables>(GqlCommunityRequestCashoutDocument, options);
      }
export type GqlCommunityRequestCashoutMutationHookResult = ReturnType<typeof useGqlCommunityRequestCashoutMutation>;
export type GqlCommunityRequestCashoutMutationResult = Apollo.MutationResult<GqlCommunityRequestCashoutMutation>;
export type GqlCommunityRequestCashoutMutationOptions = Apollo.BaseMutationOptions<GqlCommunityRequestCashoutMutation, GqlCommunityRequestCashoutMutationVariables>;
export const GqlCommunityCurrentUserDocument = gql`
    query gqlCommunityCurrentUser($isMypage: Boolean! = true) {
  currentUser {
    id
    nickname
    ...ProfileMentor_user
    ...RewardDetail_user @include(if: $isMypage)
  }
  messageUnreadCount
}
    ${ProfileMentor_UserFragmentDoc}
${RewardDetail_UserFragmentDoc}`;

/**
 * __useGqlCommunityCurrentUserQuery__
 *
 * To run a query within a React component, call `useGqlCommunityCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityCurrentUserQuery({
 *   variables: {
 *      isMypage: // value for 'isMypage'
 *   },
 * });
 */
export function useGqlCommunityCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityCurrentUserQuery, GqlCommunityCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityCurrentUserQuery, GqlCommunityCurrentUserQueryVariables>(GqlCommunityCurrentUserDocument, options);
      }
export function useGqlCommunityCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityCurrentUserQuery, GqlCommunityCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityCurrentUserQuery, GqlCommunityCurrentUserQueryVariables>(GqlCommunityCurrentUserDocument, options);
        }
export type GqlCommunityCurrentUserQueryHookResult = ReturnType<typeof useGqlCommunityCurrentUserQuery>;
export type GqlCommunityCurrentUserLazyQueryHookResult = ReturnType<typeof useGqlCommunityCurrentUserLazyQuery>;
export type GqlCommunityCurrentUserQueryResult = Apollo.QueryResult<GqlCommunityCurrentUserQuery, GqlCommunityCurrentUserQueryVariables>;
export const GqlSearchMentorDutiesDocument = gql`
    query gqlSearchMentorDuties($filterBy: SearchMentorDutiesFilters!) {
  searchMentorDuties(filterBy: $filterBy) {
    ...SearchMentorDuties_mentorDuty
  }
}
    ${SearchMentorDuties_MentorDutyFragmentDoc}`;

/**
 * __useGqlSearchMentorDutiesQuery__
 *
 * To run a query within a React component, call `useGqlSearchMentorDutiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlSearchMentorDutiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlSearchMentorDutiesQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGqlSearchMentorDutiesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GqlSearchMentorDutiesQuery, GqlSearchMentorDutiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlSearchMentorDutiesQuery, GqlSearchMentorDutiesQueryVariables>(GqlSearchMentorDutiesDocument, options);
      }
export function useGqlSearchMentorDutiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlSearchMentorDutiesQuery, GqlSearchMentorDutiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlSearchMentorDutiesQuery, GqlSearchMentorDutiesQueryVariables>(GqlSearchMentorDutiesDocument, options);
        }
export type GqlSearchMentorDutiesQueryHookResult = ReturnType<typeof useGqlSearchMentorDutiesQuery>;
export type GqlSearchMentorDutiesLazyQueryHookResult = ReturnType<typeof useGqlSearchMentorDutiesLazyQuery>;
export type GqlSearchMentorDutiesQueryResult = Apollo.QueryResult<GqlSearchMentorDutiesQuery, GqlSearchMentorDutiesQueryVariables>;
export const GqlCommunityMentorSchoolByNameDocument = gql`
    query gqlCommunityMentorSchoolByName($name: String!) {
  mentorSchoolByName(name: $name) {
    ...ApproveInputUserInfoItem_mentorSchool
  }
}
    ${ApproveInputUserInfoItem_MentorSchoolFragmentDoc}`;

/**
 * __useGqlCommunityMentorSchoolByNameQuery__
 *
 * To run a query within a React component, call `useGqlCommunityMentorSchoolByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityMentorSchoolByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityMentorSchoolByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGqlCommunityMentorSchoolByNameQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GqlCommunityMentorSchoolByNameQuery, GqlCommunityMentorSchoolByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityMentorSchoolByNameQuery, GqlCommunityMentorSchoolByNameQueryVariables>(GqlCommunityMentorSchoolByNameDocument, options);
      }
export function useGqlCommunityMentorSchoolByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityMentorSchoolByNameQuery, GqlCommunityMentorSchoolByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityMentorSchoolByNameQuery, GqlCommunityMentorSchoolByNameQueryVariables>(GqlCommunityMentorSchoolByNameDocument, options);
        }
export type GqlCommunityMentorSchoolByNameQueryHookResult = ReturnType<typeof useGqlCommunityMentorSchoolByNameQuery>;
export type GqlCommunityMentorSchoolByNameLazyQueryHookResult = ReturnType<typeof useGqlCommunityMentorSchoolByNameLazyQuery>;
export type GqlCommunityMentorSchoolByNameQueryResult = Apollo.QueryResult<GqlCommunityMentorSchoolByNameQuery, GqlCommunityMentorSchoolByNameQueryVariables>;
export const GqlCommunityViewerRecommendedMentorPostsDocument = gql`
    query gqlCommunityViewerRecommendedMentorPosts($pagination: Pagination, $filterBy: ViewerRecommendedMentorPostFilters) {
  viewerRecommendedMentorPosts(pagination: $pagination, filterBy: $filterBy) {
    nodes {
      id
      ...ReplyTableItem_post
    }
    totalCount
  }
}
    ${ReplyTableItem_PostFragmentDoc}`;

/**
 * __useGqlCommunityViewerRecommendedMentorPostsQuery__
 *
 * To run a query within a React component, call `useGqlCommunityViewerRecommendedMentorPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityViewerRecommendedMentorPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityViewerRecommendedMentorPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGqlCommunityViewerRecommendedMentorPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityViewerRecommendedMentorPostsQuery, GqlCommunityViewerRecommendedMentorPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityViewerRecommendedMentorPostsQuery, GqlCommunityViewerRecommendedMentorPostsQueryVariables>(GqlCommunityViewerRecommendedMentorPostsDocument, options);
      }
export function useGqlCommunityViewerRecommendedMentorPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityViewerRecommendedMentorPostsQuery, GqlCommunityViewerRecommendedMentorPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityViewerRecommendedMentorPostsQuery, GqlCommunityViewerRecommendedMentorPostsQueryVariables>(GqlCommunityViewerRecommendedMentorPostsDocument, options);
        }
export type GqlCommunityViewerRecommendedMentorPostsQueryHookResult = ReturnType<typeof useGqlCommunityViewerRecommendedMentorPostsQuery>;
export type GqlCommunityViewerRecommendedMentorPostsLazyQueryHookResult = ReturnType<typeof useGqlCommunityViewerRecommendedMentorPostsLazyQuery>;
export type GqlCommunityViewerRecommendedMentorPostsQueryResult = Apollo.QueryResult<GqlCommunityViewerRecommendedMentorPostsQuery, GqlCommunityViewerRecommendedMentorPostsQueryVariables>;
export const GqlCommunityViewerMentorCommentsDocument = gql`
    query gqlCommunityViewerMentorComments($pagination: Pagination) {
  viewerMentorComments(pagination: $pagination) {
    nodes {
      id
      ...MentorActivityHistoryListItem_comment
    }
    totalCount
  }
}
    ${MentorActivityHistoryListItem_CommentFragmentDoc}`;

/**
 * __useGqlCommunityViewerMentorCommentsQuery__
 *
 * To run a query within a React component, call `useGqlCommunityViewerMentorCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityViewerMentorCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityViewerMentorCommentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlCommunityViewerMentorCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityViewerMentorCommentsQuery, GqlCommunityViewerMentorCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityViewerMentorCommentsQuery, GqlCommunityViewerMentorCommentsQueryVariables>(GqlCommunityViewerMentorCommentsDocument, options);
      }
export function useGqlCommunityViewerMentorCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityViewerMentorCommentsQuery, GqlCommunityViewerMentorCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityViewerMentorCommentsQuery, GqlCommunityViewerMentorCommentsQueryVariables>(GqlCommunityViewerMentorCommentsDocument, options);
        }
export type GqlCommunityViewerMentorCommentsQueryHookResult = ReturnType<typeof useGqlCommunityViewerMentorCommentsQuery>;
export type GqlCommunityViewerMentorCommentsLazyQueryHookResult = ReturnType<typeof useGqlCommunityViewerMentorCommentsLazyQuery>;
export type GqlCommunityViewerMentorCommentsQueryResult = Apollo.QueryResult<GqlCommunityViewerMentorCommentsQuery, GqlCommunityViewerMentorCommentsQueryVariables>;
export const GqlCommunityViewerMentorRewardLogsDocument = gql`
    query gqlCommunityViewerMentorRewardLogs($pagination: Pagination) {
  viewerMentorRewardLogs(pagination: $pagination) {
    nodes {
      id
      ...MentorRewardContext_mentorRewardLog
    }
    totalCount
  }
}
    ${MentorRewardContext_MentorRewardLogFragmentDoc}`;

/**
 * __useGqlCommunityViewerMentorRewardLogsQuery__
 *
 * To run a query within a React component, call `useGqlCommunityViewerMentorRewardLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityViewerMentorRewardLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityViewerMentorRewardLogsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlCommunityViewerMentorRewardLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityViewerMentorRewardLogsQuery, GqlCommunityViewerMentorRewardLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityViewerMentorRewardLogsQuery, GqlCommunityViewerMentorRewardLogsQueryVariables>(GqlCommunityViewerMentorRewardLogsDocument, options);
      }
export function useGqlCommunityViewerMentorRewardLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityViewerMentorRewardLogsQuery, GqlCommunityViewerMentorRewardLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityViewerMentorRewardLogsQuery, GqlCommunityViewerMentorRewardLogsQueryVariables>(GqlCommunityViewerMentorRewardLogsDocument, options);
        }
export type GqlCommunityViewerMentorRewardLogsQueryHookResult = ReturnType<typeof useGqlCommunityViewerMentorRewardLogsQuery>;
export type GqlCommunityViewerMentorRewardLogsLazyQueryHookResult = ReturnType<typeof useGqlCommunityViewerMentorRewardLogsLazyQuery>;
export type GqlCommunityViewerMentorRewardLogsQueryResult = Apollo.QueryResult<GqlCommunityViewerMentorRewardLogsQuery, GqlCommunityViewerMentorRewardLogsQueryVariables>;
export const GqlCommunityMentorRewardAmountSettingsDocument = gql`
    query gqlCommunityMentorRewardAmountSettings {
  mentorRewardAmountSettings {
    id
    ...ReplyTable_rewardAmountSettings
  }
}
    ${ReplyTable_RewardAmountSettingsFragmentDoc}`;

/**
 * __useGqlCommunityMentorRewardAmountSettingsQuery__
 *
 * To run a query within a React component, call `useGqlCommunityMentorRewardAmountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityMentorRewardAmountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityMentorRewardAmountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGqlCommunityMentorRewardAmountSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityMentorRewardAmountSettingsQuery, GqlCommunityMentorRewardAmountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityMentorRewardAmountSettingsQuery, GqlCommunityMentorRewardAmountSettingsQueryVariables>(GqlCommunityMentorRewardAmountSettingsDocument, options);
      }
export function useGqlCommunityMentorRewardAmountSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityMentorRewardAmountSettingsQuery, GqlCommunityMentorRewardAmountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityMentorRewardAmountSettingsQuery, GqlCommunityMentorRewardAmountSettingsQueryVariables>(GqlCommunityMentorRewardAmountSettingsDocument, options);
        }
export type GqlCommunityMentorRewardAmountSettingsQueryHookResult = ReturnType<typeof useGqlCommunityMentorRewardAmountSettingsQuery>;
export type GqlCommunityMentorRewardAmountSettingsLazyQueryHookResult = ReturnType<typeof useGqlCommunityMentorRewardAmountSettingsLazyQuery>;
export type GqlCommunityMentorRewardAmountSettingsQueryResult = Apollo.QueryResult<GqlCommunityMentorRewardAmountSettingsQuery, GqlCommunityMentorRewardAmountSettingsQueryVariables>;
export const GqlCommunityMentorBoardsDocument = gql`
    query gqlCommunityMentorBoards {
  mentorBoards {
    nodes {
      id
      ...MentorBoardListItem_board
    }
    totalCount
  }
}
    ${MentorBoardListItem_BoardFragmentDoc}`;

/**
 * __useGqlCommunityMentorBoardsQuery__
 *
 * To run a query within a React component, call `useGqlCommunityMentorBoardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityMentorBoardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityMentorBoardsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGqlCommunityMentorBoardsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityMentorBoardsQuery, GqlCommunityMentorBoardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityMentorBoardsQuery, GqlCommunityMentorBoardsQueryVariables>(GqlCommunityMentorBoardsDocument, options);
      }
export function useGqlCommunityMentorBoardsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityMentorBoardsQuery, GqlCommunityMentorBoardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityMentorBoardsQuery, GqlCommunityMentorBoardsQueryVariables>(GqlCommunityMentorBoardsDocument, options);
        }
export type GqlCommunityMentorBoardsQueryHookResult = ReturnType<typeof useGqlCommunityMentorBoardsQuery>;
export type GqlCommunityMentorBoardsLazyQueryHookResult = ReturnType<typeof useGqlCommunityMentorBoardsLazyQuery>;
export type GqlCommunityMentorBoardsQueryResult = Apollo.QueryResult<GqlCommunityMentorBoardsQuery, GqlCommunityMentorBoardsQueryVariables>;
export const GqlCommunityReplyCountDocument = gql`
    query gqlCommunityReplyCount {
  viewerRecommendedMentorPosts(filterBy: {matchDutyName: true, matchOrganizationName: true, matchSchoolName: true}) {
    totalCount
  }
}
    `;

/**
 * __useGqlCommunityReplyCountQuery__
 *
 * To run a query within a React component, call `useGqlCommunityReplyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlCommunityReplyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlCommunityReplyCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGqlCommunityReplyCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GqlCommunityReplyCountQuery, GqlCommunityReplyCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlCommunityReplyCountQuery, GqlCommunityReplyCountQueryVariables>(GqlCommunityReplyCountDocument, options);
      }
export function useGqlCommunityReplyCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlCommunityReplyCountQuery, GqlCommunityReplyCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlCommunityReplyCountQuery, GqlCommunityReplyCountQueryVariables>(GqlCommunityReplyCountDocument, options);
        }
export type GqlCommunityReplyCountQueryHookResult = ReturnType<typeof useGqlCommunityReplyCountQuery>;
export type GqlCommunityReplyCountLazyQueryHookResult = ReturnType<typeof useGqlCommunityReplyCountLazyQuery>;
export type GqlCommunityReplyCountQueryResult = Apollo.QueryResult<GqlCommunityReplyCountQuery, GqlCommunityReplyCountQueryVariables>;
export const GqlBoardBySlugDocument = gql`
    query gqlBoardBySlug($boardSlug: String!, $orderBy: PostOrder, $pagination: Pagination) {
  boardBySlug(boardSlug: $boardSlug) {
    ...useGetBoardBySlug_Board
    posts(orderBy: $orderBy, pagination: $pagination) {
      ...useGetBoardBySlug_PostConnection
      totalCount
    }
  }
}
    ${UseGetBoardBySlug_BoardFragmentDoc}
${UseGetBoardBySlug_PostConnectionFragmentDoc}`;

/**
 * __useGqlBoardBySlugQuery__
 *
 * To run a query within a React component, call `useGqlBoardBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGqlBoardBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGqlBoardBySlugQuery({
 *   variables: {
 *      boardSlug: // value for 'boardSlug'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGqlBoardBySlugQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GqlBoardBySlugQuery, GqlBoardBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GqlBoardBySlugQuery, GqlBoardBySlugQueryVariables>(GqlBoardBySlugDocument, options);
      }
export function useGqlBoardBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GqlBoardBySlugQuery, GqlBoardBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GqlBoardBySlugQuery, GqlBoardBySlugQueryVariables>(GqlBoardBySlugDocument, options);
        }
export type GqlBoardBySlugQueryHookResult = ReturnType<typeof useGqlBoardBySlugQuery>;
export type GqlBoardBySlugLazyQueryHookResult = ReturnType<typeof useGqlBoardBySlugLazyQuery>;
export type GqlBoardBySlugQueryResult = Apollo.QueryResult<GqlBoardBySlugQuery, GqlBoardBySlugQueryVariables>;